import React, { useEffect } from 'react';
import { format } from 'date-fns';

const DatePicker = (props) => {

  const {id, form, onChange} = props;
  const data = form && form[id] ? form[id] : null;
  const required = data.required !== undefined ? data.required : true;
  const label = data.label !== undefined ? data.label : null;
  const value = data.value ? data.value : '';

  useEffect(() => {
    setTimeout(function(){
      const M = window.M;
      if(M) {
        M.Datepicker.init(document.querySelectorAll('.datepicker'), {
          setDefaultDate: true,
          defaultDate: value ? new Date(value) : null,
          maxDate: new Date(),
          yearRange: [1921,new Date().getFullYear()-18],
          changeMonth: true,
          changeYear: true,
          firstDay: 1,
          format: 'yyyy-mm-dd',
          onSelect: (val) => {
            const date = format(new Date(val), 'yyyy-MM-dd')
            onChange({target:{validity:{valid:true},value:date}}, id);
          }
        });
      }
    })
  }, []);

  const onFocus = () => {
    const elems = document.querySelectorAll('.datepicker');
    const datepicker = M.Datepicker.getInstance(elems[0]);
    datepicker.open();
  }

  return id ? (
    <div className={`input-field`}>
      <input id={id}
        type="text"
        className="datepicker validate"
        value={value}
        onChange={()=>{}}
        onKeyDown={(e) => {e&&e.code==='Enter'&&e.preventDefault()}}
        onFocus={()=>onFocus()}
        required={required} ></input>
      <label className="active" htmlFor={id}>{label} {required?<sup>*</sup>:null}</label>
    </div>
  ) : null;
}
export {DatePicker};