import React, { useEffect } from 'react';

const Radio = (props) => {

  const {id, form, options, onChange} = props;
  const data = form && form[id] ? form[id] : null;
  const required = data.required !== undefined ? data.required : true;
  const label = data.label !== undefined ? data.label : null;
  const value = data.value ? data.value : '';

  return id ? (
    <div className={`radio-field`}>
      {label?<label className="radio-label active" htmlFor={id}>{label} {required ? <sup>*</sup> : null}</label>:null}
      {options.map((option, index)=>(
        <div key={`${id}_${index}`} className="radio-button">
          <label>
            <input name={id} type="radio"
            className="validate"
            onKeyDown={(e) => {e&&e.code==='Enter'&&e.preventDefault()}}
            onChange={(e)=>onChange({target:{validity:{valid:true},value:option.value}}, id)}
            checked={option.value===value}
            required={required}
            />
            <span>{option.label}</span>
          </label>
        </div>
      ))}
      <style>{`
        .radio-label {
          display: block;
          width: 100%;
          height: 30px;
        }
        .radio-button {
          display: inline-flex;
          padding-top: 10px;
          padding-right: 10px;
          text-transform: capitalize;
        }
      `}</style>
    </div>
  ) : null;
}
export {Radio};