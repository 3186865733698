
import { assetForCoingecko } from './../utils/utils';

export async function getProfileApi(email=null) {
  const response = await fetch(`/api/profile${email?'?email='+email:''}`)
  return await response.json();
}

export async function setProfileApi(data) {
  const requestOptions = {
      headers:{ 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(data),
  };
  return fetch('/api/profile', requestOptions);
}

export async function getFilesApi() {
  const response = await fetch(`/api/profile/files`)
  return await response.json();
}

export async function saveFilesApi(data) {
  const requestOptions = {
      method: 'POST',
      body: data,
  };
  return fetch('/api/profile/files', requestOptions);
}

export async function getUserApi(email=null) {
  const resp = await fetch(`/api/user${email?'?email='+email:''}`)
  return await resp.json()
}

export async function getUserBalanceApi(email=null) {
  const url = `/api/user/balance${email?'?email='+email:''}`;
  const resp = await fetch(url);
  return await resp.json();
}

export async function getBalancesApi() {
  const url = `/api/balances`;
  const resp = await fetch(url);
  return await resp.json();
}

export async function getAssetsApi() {
  const url = `/api/assets`;
  const resp = await fetch(url);
  return await resp.json();
}

export async function getAssetsCoingecko(assets) {
  let assetId = '';
  for(const asset of assets) {
    assetId += assetForCoingecko(asset)+',';
  }
  const response = await fetch("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids="+assetId)
  const data = await response.json();
  return data.map(d=>Object.assign({...d, ...{symbol: d.symbol.toUpperCase()}}))
}

export async function getHistoricalCoingecko(asset) {
  const response = await fetch(`https://api.coingecko.com/api/v3/coins/${asset.id}/market_chart?vs_currency=usd&interval=hourly&days=30`)
  return await response.json();
}
