import React, { useEffect } from 'react';

const Textarea = (props) => {

  const {id, form, onChange} = props;
  const data = form && form[id] ? form[id] : null;
  const required = data.required !== undefined ? data.required : true;
  const label = data.label !== undefined ? data.label : null;
  const value = data.value ? data.value : '';

  return id ? (
    <div className={`textarea-field`}>
      <label className="active" htmlFor={id}>{label} {required ? <sup>*</sup> : null}</label>
      <textarea
        id={id}
        className="materialize-textarea textarea-element validate"
        value={value}
        onChange={(e) => {onChange(e, id)}}
        required={required}
        ></textarea>
      <style>{`
        .textarea-field {
          min-height: 94px;
          padding-top: 2px;
        }
      `}</style>
    </div>
  ) : null;
}
export {Textarea};