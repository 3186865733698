import React, { useState, useEffect, useMemo } from 'react';

import { Radio, Textarea, FilePicker } from './../../components/Form';
import { getUserStorage } from './../../utils/storage';

export default (props) => {

  const [sent, setSent] = useState(false);
  const {single, info, form, setField, save, prev, isValid} = props;

  const sendEmail = (event) => {
    event.preventDefault();
    const user = getUserStorage();
    var body = {
      user: {
        email: user.email
      }
    }
    const requestOptions = {
        headers:{ 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(body),
    };
    fetch('/users/password', requestOptions)
      .then(async (response) => {
        setSent(true);
      });
  }

  return (
    <form className="row" onSubmit={(event)=>{event.preventDefault()}}>
      <div className="col s12">
        <div className="row">
          <p>In order to change your password, we will send you an email to you email address.</p>
        </div>
        <div className="row">
        { sent ? (
          <p>Please, review your email</p>
        ) : (
          <button className="left waves-effect black btn" onClick={(e)=>sendEmail(e)}>Request password change</button>
        )}
        </div>
      </div>
    </form>
  );
}
