import React from "react";
import MaterializeStyles from './MaterializeStyles';
import HighchartsStyles from './HighchartsStyles';

export default () => (
  <>
    <style>{`
      html,
      body {
        width: 100%;
        padding: 0;
        margin: 0;
        font-family: Poppins;
        scroll-behavior: smooth !important;
        font-weight: 400;
        color: #2C2E30;
      }
      * {
        box-sizing: border-box;
      }
      h1, h2, h3, h4, h5, h6 {
        line-height: 125%;
      }
      h1, h2 {
        font-family: 'SpaceGrotesk', sans-serif;
        font-weight: bold;
      }
      h1 { font-size: 36px; }
      h2 { margin-bottom: 32px; font-size: 32px; }
      h3, h4, h5, h6 { font-weight: bold; }
      h3 { font-size: 28px; }
      h4 { font-size: 22px; }
      h5 { font-size: 18px; }
      h6 { font-size: 16px; }
      .btn {
        border-radius: 5px;
      }
      .btn[disabled] {
        opacity: .5;
      }
      .btn.transparent {
        box-shadow: none;
        color: black;
      }
      .link {
        text-decoration: underline;
        font-weight: bold;
        cursor: pointer;
        color: black;
      }
      .container-full {
        padding: 20px 0;
      }
      .container-full.green {
        background: #00FF79 !important;
        border-bottom: none;
      }
      .container-full.container-sidebar .sidebar {
        padding: 0 10px 20px;
        border: 1px solid #f1f1f1;
        border-radius: 4px;
      }
      @media (max-width: 600px) {
        .container-full.container-sidebar .sidebar {
          padding: 0 0 20px;
        }
      }
      .container-full.container-sidebar .sidebar a {
        display: block;
        line-height: 40px;
        color: black;
        font-size: 12px;
        cursor: pointer;
      }
      .container-full.container-sidebar .sidebar a.black {
        background: black;
        text-indent: 23px;
        color: white;
      }
      @media (max-width: 992px) {
        .container-full.container-sidebar .sidebar a {
          text-align: center;
        }
      }
      .container-full.container-sidebar .sidebar a .material-icons {
        vertical-align: text-bottom;
        margin-left: 4px;
        font-size: 18px;
      }
      .container-full.container-sidebar .sidebar a span {
        display: inline-block;
        vertical-align: top;
        text-indent: 6px;
      }
      .container-full.container-sidebar .sidebar a:hover {
        font-weight: bold;
      }
      .container-full.container-sidebar .content {
        padding: 10px 20px 40px;
      }

      @media (max-width: 600px) {
        h1 { font-size: 32px; }
        h2 { margin-bottom: 32px; font-size: 28px; }
        h3 { font-size: 24px; }
        h4 { font-size: 20px; }
        h5 { font-size: 16px; }
        h6 { font-size: 14px; }
        .container-full {
          padding: 40px 20px;
        }
      }
    `}</style>
    <HighchartsStyles/>
    <MaterializeStyles/>
  </>
);