import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { Input } from './../../components/Form';
import { setFormField } from './../../utils/forms';
import { validateUser } from './../../utils/user';
import { getUserStorage } from './../../utils/storage';
import AuthStyles from './../../styles/AuthStyles';

const ResetPasswordForm = () => {
  const history = useHistory();

  const [form, setForm] = useState({
    password: {value: null, valid: null, required: true, type: 'password', placeholder: 'Your password', minLength: 6},
    passwordConfirmation: {value: null, valid: null, required: true, type: 'password', placeholder: 'Repeat your password', minLength: 6},
    reset_password_token: {value: null, valid: null, required: true},
  })
  const [isSending, setIsSending] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const user = getUserStorage();
    setCurrentUser(user);
    const urlParams = new URLSearchParams(window.location.search);
    const reset_password_token = urlParams.get('reset_password_token');
    if(reset_password_token) {
      const updateForm = {...form};
      updateForm.reset_password_token.value = reset_password_token;
      updateForm.reset_password_token.valid = true;
      setForm(updateForm);
    }else{
      history.push('/signin');
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    grecaptcha.ready(function() {
      grecaptcha.execute('6LdHAyYcAAAAAKKL8tKdZ_u7aeETcI09DZg-AP11', {action: 'reset'}).then(function(token) {
        if(isSending) return;
        setIsSending(true);
        var body = {
          user: {
            password: form.password.value,
            passwordConfirmation: form.passwordConfirmation.value,
            reset_password_token: form.reset_password_token.value,
          }
        }
        const requestOptions = {
            headers:{ 'Content-Type': 'application/json' },
            method: 'PUT',
            body: JSON.stringify(body),
        };
        fetch('/users/password', requestOptions)
          .then(async (response) => {
            setIsSending(false);
            document.getElementsByClassName('grecaptcha-badge')[0].remove();
            window.location.href = '/dashboard';
          }).catch(()=>{
            setError('Some error happened');
            setIsSending(false);
          });

      });
    });
  }

  const setField = (event, key) => {
    const formUpdated = setFormField(event, key, form);
    setForm(formUpdated);
    const formValid = validateUser(formUpdated);
    setIsValid(formValid.valid);
    if(!formValid.valid && formValid.message) setError(formValid.message)
    else setError(null);

  }

  return (
    <>
      <div id="login-form" className="AuthForm">
        <div className="container-full green">
          <div className="container row">
            <section className="col m4 s12">
              <div className="row">
                <div className="col s12">
                  <h2 className="title">Change your password</h2>
                </div>
              </div>
              <div className="row">
                <form className="col s12">
                  <div className="input-row row">
                    <div className="col">
                      <label>Set your new password (6 characters minimum)</label>
                    </div>
                  </div>
                  <div className="input-row row">
                    <div className="col s12">
                      <Input 
                        id="password"
                        form={form}
                        onChange={setField}
                      />
                    </div>
                  </div>
                  <div className="input-row row">
                    <div className="col s12">
                      <Input 
                        id="passwordConfirmation"
                        form={form}
                        onChange={setField}
                      />
                    </div>
                  </div>
                  <div className="input-row row">
                    <div className="input-field col s12">
                      <button className="g-recaptcha waves-effect black btn" disabled={!isValid||isSending} onClick={(e)=>{onSubmit(e)}}>
                        Save
                      </button>
                      {!currentUser ? <button className="g-recaptcha waves-effect transparent btn" onClick={(e)=>{history.push('/signin')}}>
                        Log in
                      </button> : null}
                      {error ? (
                        <h6 className="form-error">{error}</h6>
                      ):null}
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </div>
      <AuthStyles/>
    </>
  );
};

export default ResetPasswordForm;

