import React, { useEffect } from "react";
import SignInForm from './../components/Auth/SignInForm';
import Header from './../components/Layout/Header';

import { faqs } from './../utils/consts';

export default () => {

  useEffect(() => {
    setTimeout(function(){
      const M = window.M;
      if(M) {
        M.Collapsible.init(document.querySelectorAll('.collapsible'), {});
      }
    })
  }, []);

  return (
    <>
      <main>
        <Header></Header>
        <div className="Faqs">
          <div className="container-full">
            <div className="container row">
              <div className="col s12">
                <h2>Frequently Asked Questions.</h2>
              </div>
            </div>
            {faqs.map((faq, index)=>(
              <div key={`faq${index}`} className="container row"> 
                <section className="col s12">
                  <h3>{faq.title}</h3>
                  <ul className="collapsible">
                    {faq.questions.map((question, indexQ)=>(
                      <li key={`faq${index}q${indexQ}`}>
                        <div className="collapsible-header"><b>{indexQ+1}.</b> {question.question}</div>
                        <div className="collapsible-body"><span dangerouslySetInnerHTML={{__html: question.answer}}></span></div>
                      </li>
                    ))}
                  </ul>
                </section>
              </div>
            ))}
          </div>
        </div>
        <style>{`
          .Faqs .container-full {
            min-height: 100vh;
            padding-top: 240px;
          }
          .Faqs h2 {
            margin: 0;
          }
          .Faqs .collapsible,
          .Faqs .collapsible-header {
            background: none;
            box-shadow: none;
            border: none;
          }
          .Faqs .collapsible {
            border: 1px solid #000;
          }
          .Faqs .collapsible > li {
            border-top: 1px solid #000;
          }
          .Faqs .collapsible > li:first-child {
            border-top: none;
          }
          .Faqs .collapsible > li ul {
            padding-left: 20px;
          }
          .Faqs .collapsible > li li {
            list-style: disc;
          }
          .Faqs .collapsible b {
            display: inline-block;
            width: 25px;
          }
        `}</style>
      </main>
    </>
  );
}
