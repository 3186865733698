import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from "react-router-dom";

import Steps from './Steps';
import { getProfileApi, setProfileApi, getFilesApi, saveFilesApi } from './../../requests';
import { validateForm, setFormData, getFormData, setFormField } from './../../utils/forms';

import AuthHeader from './../../components/Layout/AuthHeader';
import Footer from './../../components/Layout/Footer';
import Personal from './../../components/Profile/Personal';
import Professional from './../../components/Profile/Professional';
import Bank from './../../components/Profile/Bank';
import Suitability from './../../components/Profile/Suitability';
import Passport from './../../components/Profile/Passport';
import Residence from './../../components/Profile/Residence';
import Risk from './../../components/Profile/Risk';
import Agreement from './../../components/Profile/Agreement';
import { forms } from './../../components/Profile/forms';

import ProfileStyles from './../../styles/ProfileStyles';

export default () => {
  const history = useHistory();
  const location = useLocation();
  const stepIndex = forms.findIndex((form)=>form.url.value === location.pathname);

  const [info, setInfo] = useState(null)
  const [form, setForm] = useState(null)
  const [step, setStep] = useState(null)

  const [isValid, setIsValid] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  useEffect(async () => {
    const info = await getProfileApi();
    const files = await getFilesApi();
    const data = {...info, ...files};
    const formData = setFormData(forms[stepIndex], data);
    const formValid = validateForm(formData).valid;
    setIsValid(formValid);
    setForm(formData);
    setInfo(data);
    setStep(stepIndex)
  }, [location.pathname])

  const goToPrev = () => {
    const prev = forms[step-1].url.value;
    history.push(prev)
  }

  const goToNext = () => {
    const next = forms[step+1].url.value;
    history.push(next)
  }

  const save = async (event) => {
    setIsSaved(false);
    event.preventDefault();
    await setProfileApi(getFormData(form));
    // if(M) M.toast({html: 'The changes have been saved'});
    setIsSaved(true);
    goToNext();
  }

  const upload = async (event) => {
    setIsSaved(false);
    event.preventDefault();
    const formData = new FormData();
    if(form.document_icpassport) {
      const document_icpassport = form.document_icpassport.value;
      if(document_icpassport && typeof document_icpassport !== 'string') formData.append('document_icpassport', document_icpassport, document_icpassport.name);
    }
    if(form.document_addressproof) {
      const document_addressproof = form.document_addressproof.value;
      if(document_addressproof && typeof document_addressproof !== 'string') formData.append('document_addressproof', document_addressproof);
    }
    await saveFilesApi(formData);
    // if(M) M.toast({html: 'The changes have been saved'});
    setIsSaved(true);
    goToNext();
  }

  const setField = (event, key) => {
    const formUpdated = setFormField(event, key, form);
    setForm(formUpdated);
    const formValid = validateForm(formUpdated).valid;
    setIsValid(formValid);
  }

  const getStep = (index) => {
    switch(index) {
      case 0:
        return <Personal form={form} info={info} setField={setField} save={save} isValid={isValid} isSaved={isSaved} />
      case 1:
        return <Professional form={form} info={info} setField={setField} save={save} prev={goToPrev} isValid={isValid} isSaved={isSaved} />
      case 2:
        return <Bank form={form} info={info} setField={setField} save={save} prev={goToPrev} isValid={isValid} isSaved={isSaved} />
      case 3:
        return <Suitability form={form} info={info} setField={setField} save={save} prev={goToPrev} isValid={isValid} isSaved={isSaved} />
      case 4:
        return <Passport form={form} info={info} setField={setField} save={upload} prev={goToPrev} isValid={isValid} isSaved={isSaved} />
      case 5:
        return <Residence form={form} info={info} setField={setField} save={upload} prev={goToPrev} isValid={isValid} isSaved={isSaved} />
      case 6:
        return <Risk form={form} info={info} setField={setField} save={save} prev={goToPrev} isValid={isValid} isSaved={isSaved} />
      case 7:
        return <Agreement form={form} info={info} setField={setField} save={save} prev={goToPrev} isValid={isValid} isSaved={isSaved} />
    }
  }

  return (
    <>
      <main>
        <AuthHeader/>
        <div id="signup" className="Profile">
          <div className="container container-full">
            { form && info && step === stepIndex ? (
            <>
              <div className="row">
                <div className="col s12">
                  <h2 className="title">{stepIndex+1}. {form.title.value}</h2>
                </div>
                <div className="col s12">
                  <Steps step={stepIndex+1} form={form} info={info}/>
                </div>
              </div>
              {getStep(stepIndex)}
            </>
            ) : null }
          </div>
        </div>
        <Footer/>
      </main>
      <ProfileStyles/>
    </>
  );
}
