import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from "react-router-dom";

import { forms } from './../../components/Profile/forms';
import { validateForm, setFormData } from './../../utils/forms';

export default (props) => {
  const history = useHistory();
  const [steps, setSteps] = useState(null);

  const {form, info, step} = props;

  const isCompleted = (s, i) => {
    const data = setFormData(s, i);
    return validateForm(data).valid;
  }

  const navigate = (step) => {
    if(step.completed) history.push(step.step.url.value);
  }

  useEffect(async () => {
    const steps = Array.from(Array(8).keys()).map((index)=> (
      {id: index+1, step: forms[index], completed: isCompleted(forms[index], info)}
    ))
    setSteps(steps);
  }, [])

  return steps ? (
    <>
      <div className="Steps">
        <ul className="steps-list">
          {steps.map(st=>(
            <li key={`step_${st.id}`} className={`step ${st.completed ?'completed':''} ${st.id==step ?'current':''}`} onClick={()=>navigate(st)}></li>
          ))}
        </ul>
      </div>
      <style>{`
        .Steps {
          width: 100%;
          margin: 0 auto 100px; 
        }
        .Steps .steps-list {
          margin-left: calc(-100% / 8 / 2);
          margin-right: calc(-100% / 8 / 2);
          counter-reset: step;
        }
        .Steps .steps-list .step {
          list-style-type: none;
          width: calc(100% / 8);
          float: left;
          font-size: 12px;
          position: relative;
          text-align: center;
          text-transform: uppercase;
          color: #7d7d7d;
        }
        .Steps .steps-list li:before {
          width: 30px;
          height: 30px;
          content: '';
          counter-increment: step;
          line-height: 30px;
          border: 2px solid #7d7d7d;
          display: block;
          text-align: center;
          margin: 0 auto 10px auto;
          border-radius: 50%;
          background-color: white;
        }
        .Steps .steps-list li:after {
          width: 100%;
          height: 2px;
          content: '';
          position: absolute;
          background-color: #7d7d7d;
          top: 15px;
          left: -50%;
          z-index: -1;
        }
        .Steps .steps-list li:first-child:after {
          content: none;
        }
        .Steps .steps-list li.completed {
          color: #00FF79;
          cursor: pointer;
        }
        .Steps .steps-list li.current:before {
          border-color: #7d7d7d !important;
          background-color: #7d7d7d;
        }
        .Steps .steps-list li.completed:before {
          border-color: #00FF79;
          background-color: #00FF79 !important;
        }
        .Steps .steps-list li.completed + li:after {
          background-color: #00FF79;
        }
      `}</style>
    </>
  ) : null;
}
