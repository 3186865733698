import React, { useState, useEffect, useRef } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from "highcharts/highstock";

const IndexChart = (props) => {
  const chartRef = useRef()
  const {isHome, unitPrice} = props;
  const [prices, setPrices] = useState([]);
  const [period, setPeriod] = useState();
  const [chartSeries, setChartSeries] = useState(null);
  const [chartOptions, setChartOptions] = useState({
    title: { text: ''},
    credits: { enabled: false},
    legend: { enabled: false},
    chart: {
      type: 'scatter',
      animation: true,
      panning: {enabled: false},
      backgroundColor: 'rgba(255, 255, 255, 0)',

    },
    plotOptions: {
      bar: {
        borderWidth: 2
      },
      series: {
        turboThreshold: 100000,
      }
    },
    xAxis: {
      lineColor: '#919191',
      title: {text: ''},
      type: 'datetime',
      ordinal: false,
      dateTimeLabelFormats: {
        month: '%e. %b',
        year: '%b'
      },
      labels: {
          style: {
              color: 'black',
              fontSize: '7'
          }
      },
    },
    yAxis: {
      lineColor: '#919191',
      title: {
        text: 'Unit price'
      },
      labels: {
          style: {
              color: 'black',
              fontSize: '7'
          },
          formatter: function () {
            return '$ ' + this.axis.defaultLabelFormatter.call(this)
          }
      },
      gridLineColor : '#e1e1e1'
    },
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false
    },
    rangeSelector:{
      enabled:false
    },
    tooltip: {
      shared: true,
      useHTML: true,
      borderColor: 'black',
      formatter: function () {
        const date = new Intl.DateTimeFormat('en-US').format(new Date(this.x))
        return date+'<br>Unit price: $' + this.y.toFixed(2);
      }
    },
    series: [{
      type: 'line',
      lineWidth: 4,
      lineColor: 'black',
      name: 'UNIT price',
      data: []
    }]
  });

  useEffect(() => {
    loadHistoric()
  }, []);

  useEffect(() => {
    addPoint(unitPrice)
  }, [unitPrice]);

  useEffect(() => {
    if(period) {
      var d = new Date();
      switch(period) {
        case '1W':
          d.setDate(d.getDate() - 7);
          break;
        case '1M':
          d.setMonth(d.getMonth() - 1);
          break;
        case '3M':
          d.setMonth(d.getMonth() - 3);
          break;
        case 'YTD':
          d = new Date(new Date().getFullYear(), 0, 1);
          break;
        case 'ALL':
          d = new Date(2020, 0, 1);
          break;
      }
      d.setHours(0, 0, 0, 0);
      const data = []
      prices.map(function(price, index){
        if(new Date(price[0])>new Date(d)) data.push(price);
      })
      const series = [{
        type: 'line', 
        lineWidth: 2, 
        lineColor: '#00FF79', 
        data: data, 
        name: 'price',
        zIndex: 1
      }]
      setChartOptions({series});
      setChartSeries(series);
    }
  }, [period]);

  async function loadHistoric() {
    const resp = await fetch(`/frontend/historic`)
    const data = await resp.json()
    const points = [];
    data.forEach(function(price){
      const date = new Date(price.at).getTime();
      points.push([date, Number(price.rate)])
    })
    const series = [{ 
      type: 'line', 
      lineWidth: 2, 
      lineColor: '#00FF79', 
      data: points, 
      name: 'price',
      zIndex: 1
    }]
    setChartOptions({series});
    setChartSeries(series);
    setPrices(points);
  }
  const addPoint = (point) => {
    if(chartRef.current.chart.series && chartRef.current.chart.series.length) {
      const date = new Date().getTime();
      chartRef.current.chart.series[0].addPoint([date, Number(point)]);
    }
  }
  return (
    <>
      <div className="IndexChart">
        <div className={`${isHome?'container-full':''}`}>
          {isHome ? (
            <div className="container row">
              <h2 className="col m6">Watch your investment grow day by day</h2>
            </div>
          ) : null }
          <div className={`${isHome?'container':''} row`}>
            <div className="period-bar">
              <button className={`period ${period==='1W'?'active':''}`} onClick={()=>{setPeriod('1W')}}>1W</button>
              <button className={`period ${period==='1M'?'active':''}`} onClick={()=>{setPeriod('1M')}}>1M</button>
              <button className={`period ${period==='3M'?'active':''}`} onClick={()=>{setPeriod('3M')}}>3M</button>
              <button className={`period ${period==='YTD'?'active':''}`} onClick={()=>{setPeriod('YTD')}}>YTD</button>
              <button className={`period ${!period||period==='ALL'?'active':''}`} onClick={()=>{setPeriod('ALL')}}>ALL</button>
            </div>
            <HighchartsReact
              ref={chartRef}
              highcharts={Highcharts}
              options={chartOptions}
              constructorType={"stockChart"}
            />
            {isHome ? (
              <div className="row">
                <div className="col s12">
                  <button onClick={()=>history.push('/signup')} className="waves-effect invest-btn black btn btn-large">INVEST NOW</button>
                </div>
              </div>
            ) : null }
          </div>
        </div>
      </div>
      <style>
        {`
          .IndexChart {
            padding: 0;
          }
          .IndexChart .container-full {
            padding-left: 0px;
            padding-right: 0px;
          }
          .IndexChart .container {
            padding-top: 20px;
            border-top: 1px solid #E7EAEE;
          }
          .IndexChart .period-bar {
            padding-right: 20px;
            text-align: right;
          }
          .IndexChart .period-bar .period {
            padding: 2px 10px;
            background: none;
            border: none;
          }
          .IndexChart .period-bar .period.active {
            background: black;
            color: white;
          }
          .IndexChart .invest-btn {
            display: block;
            margin: 120px auto 0;
            width: 240px;
          }
        `}
      </style>
    </>
  );
};

export default IndexChart;

