import React from "react";

export default () => (
  <style>{`
    .AuthForm .container-full {
      min-height: 100vh;
      padding-top: 240px;
    }
    .AuthForm .title {
      margin-top: 0;
      margin-bottom: 0;
    }
    .AuthForm form {
      margin-top: 20px;
    }
    .AuthForm .input-row {
      min-height: 54px;
      margin-bottom: 0;
    }
    .AuthForm .input-row .input-label {
      min-height: 28px;
      line-height: 28px;
    }
    .AuthForm .input-field {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
    .AuthForm input,
    .AuthForm textarea {
      margin-bottom: 10px !important;
      padding: 4px 8px !important;
      background: white !important;
      box-sizing: border-box !important;
      border-radius: 5px !important;
      font-size: 14px !important;
    }
    .AuthForm textarea {
      min-height: 120px;
      padding-top: 14px !important;
    }
    .AuthForm .btn {
      height: 48px;
      padding: 0 24px;
      line-height: 48px;
      font-weight: normal;
    }
    .AuthForm .btn.transparent {
      background: none !important;
      border: none !important;
      box-shadow: none !important;
      color: black;
    }
    .AuthForm .btn.link {
      padding-left: 0;
    }
    .AuthForm .checkbox-button span {
      font-size: 12px !important;
    }
    .AuthForm .grecaptcha-badge { 
      visibility: hidden;
    }
    .AuthForm .form-error {
      margin-top: 20px;
      text-transform: uppercase;
      font-size: 16px;
      color: red;
    }
    .AuthForm .form-success {
      margin-top: 20px;
      text-transform: uppercase;
      font-size: 16px;
      color: green;
    }
  `}</style>
);