import React from 'react';

export const validateForm = (form) => {
  var formValid = true;
  for(const key in form) {
    const field = form[key];
    if(field.required) {
      if(!field.value || !field.valid) return {valid: false, message: false};
    }
  }
  return {valid: true};
}

export const setFormField = (event, key, data) => {
  const target = event.target || event.nativeElement.target;

  const {value, validity} = target;
  const {valid} = validity;

  const dataUpdate = {...data};
  dataUpdate[key].value = value;
  dataUpdate[key].valid = valid;
  return dataUpdate;
}

export const setFormData = (form, data) => {
  for(const field in data) {
    if(form[field]) {
      form[field].value = data[field];
      form[field].valid = true;
    }
  }
  return form;
}

export const getFormData = (form) => {
  const data = {};
  for(const field in form) {
    data[field] = form[field].value;
  }
  return data;
}