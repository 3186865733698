import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { getUserStorage, deleteUserStorage } from './../../utils/storage';
import AbacusCarbonoImage from '../../../../assets/images/abacus-carbono.png';

const AuthHeader = (props) => {
  const history = useHistory();

  const [user, setUser] = useState(null);

  const {menu, loggedIn} = props;

  useEffect(() => {
    const M = window.M;
    if(M) {
      var instances = M.Dropdown.init(document.querySelectorAll('.dropdown-trigger'), {});
    }
    const user = getUserStorage();
    setUser(user);
  }, []);

  const dashboard = async (event) => {
    history.push('/dashboard');
  }
  const signup = async (event) => {
    history.push('/profile');
  }
  const signout = async (event) => {
    event.preventDefault();
    const requestOptions = {
        headers:{ 'Content-Type': 'application/json' },
        method: 'DELETE'
    };
    fetch('/users/sign_out', requestOptions);
    deleteUserStorage();
    window.location.href = '/';
  }

  return (
    <>
      <div className="Header">
        <nav className="container row transparent">
          <div className="col s12 nav-wrapper transparent">
            <a onClick={()=>history.push('/')} className="brand-logo">
              <img src={AbacusCarbonoImage} width={256} height={22}/>
            </a>
            <a className='right waves-effect transparent btn dropdown-trigger' href='#' data-target='mainMenu'>
              <i className="material-icons">&#xe5d2;</i>
            </a>
            <ul id="mainMenu" className="dropdown-content">
              <li><a onClick={(e)=>dashboard(e)}>My account</a></li>
              <li><a onClick={(e)=>signup(e)}>Edit profile</a></li>
              <li><a onClick={(e)=>signout(e)}>Log out</a></li>
            </ul>
            {user && user.role === 'admin' ? (
              <a className='right waves-effect black btn' href='/admin'>
                Admin
              </a>
            ):null}
          </div>
        </nav>
      </div>
      <style>
        {`
          .Header .container {
            transform: translateX(-50%);
            position: absolute;
            top: 0;
            left: 50vw;
            padding-top: 10px;
            box-shadow: none;
          }
          .Header nav .nav-wrapper {
            width: 100%;
          }
          .Header nav .brand-logo {
            font-size: 14px;
            cursor: pointer;
          }
          .Header nav .brand-logo img {
            max-width: 70vw;
            height: auto;
          }
          .Header .dropdown-content {
            top: 60px !important;
            min-width: 120px;
          }
          .Header .dropdown-content li {
            min-width: 120px;
          }
          .Header .dropdown-content a {
            display: block;
            min-width: 120px;
            font-size: 14px;
            color: black;
          }
          .Header .btn {
            margin-top: 12px;
            margin-left: 12px;
            padding: 0 6px;
            font-weight: 300;
            text-transform: capitalize;
          }
          .Header .btn i {
            height: 36px;
            line-height: 36px;
          }
          @media (max-width: 600px) {
            .Header .btn {
              margin-top: 16px;
              padding: 0 16px;
              font-size: 12px;
            }
            .Header nav .brand-logo {
              transform: translate(0, 10px);
              left: 12px;
            }
            .Header nav .brand-logo img {
              max-width: 60vw;
            }
          }
        `}
      </style>
    </>
  );
};

export default AuthHeader;
