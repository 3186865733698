import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import AuthHeader from './../components/Layout/AuthHeader';
import Pending from './../components/Dashboard/Pending';
import Verified from './../components/Dashboard/Verified';
import Investor from './../components/Dashboard/Investor';
import Withdrawn from './../components/Dashboard/Withdrawn';
import Footer from './../components/Layout/Footer';
import { getUserApi, getUserBalanceApi } from './../requests';
import { setUserStorage } from './../utils/storage';
import { getParamFromUrl } from './../utils/utils';

export default () => {
  const history = useHistory();
  const [user, setUser] = useState();
  const [balance, setBalance] = useState(null);
  const [updated, setUpdated] = useState(null);
  const [units, setUnits] = useState(null);

  useEffect( async () => {
    getUser();
  }, []);

  const getUser = async () => {
    const user = await getUserApi();
    setUserStorage(user);
    const email = getParamFromUrl('email');
    if(user.role === 'admin' && email) {
      const userViewed = await getUserApi(email);
      showUser(userViewed, true);
    }else{
      showUser(user);
    }
  }
  const showUser = (user, viewingUser) => {
    if(["uncomplete","unverified"].includes(user.kyc_status)) return history.push(`/welcome${viewingUser?'?email='+user.email:''}`);
    setUser(user);
    getBalance(user);
  }

  const getBalance = async (user) => {
    const data = await getUserBalanceApi(user.email);
    setUpdated(data.updated);
    setBalance(data.balance);
    setUnits(data.units);
  }
  return user && balance !== null ? (
    <>
      <main>
        <AuthHeader/>
        {user.kyc_status !== 'verified' && <Pending/>}
        {user.kyc_status === 'verified' && !user.has_withdrawn && !balance && <Verified/>}
        {user.kyc_status === 'verified' && !user.has_withdrawn && balance && units && <Investor balance={balance} units={units} updated={updated}/>}
        {user.kyc_status === 'verified' && user.has_withdrawn && <Withdrawn/>}
        <Footer/>
      </main>
    </>
  ) : null;
}
