import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from "react-router-dom";

export default () => {

  const history = useHistory();

  const startInvesting = (event) => {
    event.preventDefault();
    history.push('/signup/1-personal-info');
  }

  return (
    <form className="row" onSubmit={(event)=>{event.preventDefault()}}>
      <div className="col s12">
        <div className="row">
          <p>Thank you for signing up. You are just a few steps away from becoming an investor in crypto.</p>
        </div>
        <div className="row">
          <button className="left waves-effect black btn" onClick={(e)=>startInvesting(e)}>Start now</button>
        </div>
      </div>
    </form>
  );
}
