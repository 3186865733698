import React from "react";

export default () => (
  <style>{`
    .Profile .container-full {
      min-height: 100vh;
      padding-top: 140px;
    }
    @media (max-width: 600px) {
      .Profile .container-full {
        width: 100%;
      }
    }
    .Profile .column:first-child {
      border-right: 1px solid #f1f1f1;
    }
    @media (max-width: 600px) {
      .Profile .column:first-child {
        border-right: none;
      }
    }
    .Profile .title {
      margin-top: 0;
      padding-left: 10px;
    }
    .Profile .row {
      min-height: 79px;
      margin: auto;
      margin-bottom: 0;
    }
    .Profile .link {
      color: black;
      text-decoration: underline;
    }
    .Profile .btn {
      height: 48px;
      padding: 0 24px;
    }
    .Profile .btn.transparent {
      padding-left: 0;
      box-shadow: none;
      color: black;
    }
    .Profile sup {
      color: red;
    }
    .Profile .footer {
      margin-top: 40px;
    }
    .Profile.Welcome {
      text-align: center !important;
    }
    .Profile.Welcome .btn {
      display: block !important;
      margin: 20px auto !important;
      width: 240px;
    }
    .Profile.Welcome ul {
      text-align: justify;
      text-justify: inter-word;
    }
    .Profile.Welcome ul li {
      list-style: disc;
    }
    .Profile .select-wrapper select:valid {
      position: absolute;
      display: block !important;
      height: 0;
      padding: 0;
      margin-top: -10px;
      border-bottom: 1px solid #00FF79;
      -webkit-box-shadow: 0 1px 0 0 #00FF79;
      box-shadow: 0 1px 0 0 #00FF79;
      z-index: 10;
    }
    .Profile textarea:valid,
    .Profile input:valid {
      border-bottom: 1px solid #00FF79;
      -webkit-box-shadow: 0 1px 0 0 #00FF79;
      box-shadow: 0 1px 0 0 #00FF79;
    }
  `}</style>
);