import React, { useState, useEffect } from 'react';

const KeyFacts = () => {
  const keyFacts = [{
    "title": "Strategy",
    "text": "A diversified portfolio of carefully chosen, liquid, promising cryptocurrencies, bought and custodied in regulated, safe partners."
  },
  {
    "title": "Investor Qualifications",
    "text": "Retail investors"
  },
  {
    "title": "Minimum Investment",
    "text": "$10,000"
  },
  {
    "title": "Management Fee",
    "text": "0.625% of Asset Value paid quarterly."
  },
  {
    "title": "Performance Fee",
    "text": "20% of any generated returns, paid quarterly, with a high-water mark."
  },
  {
    "title": "Redemptions",
    "text": "Weekly. No withdrawal fees."
  },
  {
    "title": "Jurisdiction",
    "text": "Labuan, Malaysia"
  },
  {
    "title": "Subscriptions",
    "text": "Accepted on an ongoing basis."
  },
  {
    "title": "Lock-up Period",
    "text": "None"
  }];
  return (
    <>
      <div id="key-facts" className="KeyFacts">
        <div className="container-full">
          <div className="container row">
            <div className="col s12">
              <h2>Key facts</h2>
            </div>
            {keyFacts.map((keyFact, index)=>(
            <div key={`fact${index}`} className="fact col m6 s12">
              <h4>{keyFact.title}</h4>
              <p>{keyFact.text}</p>
            </div>
            ))}
          </div>
        </div>
      </div>
      <style>
        {`
          .KeyFacts .container-full {
            padding-bottom: 0;
            padding-left: 0px;
            padding-right: 0px;
          }
          .KeyFacts h2 {
            position: relative;
            display: table;
          }
          .KeyFacts h2:after {
            content: '';
            position: absolute;
            bottom: -10px;
            left: 0;
            background: #00FF79;
            width: 100%;
            height: 4px;
          }
          .KeyFacts .fact {
            min-height: 160px;
          }
        `}
      </style>
    </>
  );
};

export default KeyFacts;

