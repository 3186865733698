import React from "react";
import ResetForm from './../components/Auth/ResetForm';
import Header from './../components/Layout/Header';

export default () => (
  <>
    <main>
      <Header></Header>
      <ResetForm/>
    </main>
  </>
);
