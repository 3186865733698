import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { Input, Checkbox } from './../../components/Form';
import { setUserStorage } from './../../utils/storage';
import { setFormField } from './../../utils/forms';
import { validateUser } from './../../utils/user';
import { navigateTo } from './../../utils/utils';
import AuthStyles from './../../styles/AuthStyles';

const SignUpForm = () => {
  const history = useHistory();

  const [form, setForm] = useState({
    email: {value: null, valid: null, required: true, type: 'email', placeholder: 'Your email'},
    password: {value: null, valid: null, required: true, type: 'password', minLength: 6, placeholder: 'Your password'},
    passwordConfirmation: {value: null, valid: null, required: true, type: 'password', minLength: 6, placeholder: 'Repeat your password'},
    checkedTerms: {value: false, valid: null, required: true, label: 'I agree to the <a href="/abacus-carbono-terms-and-conditions.pdf" target="_blank">terms and conditions</a>'}
  })
  const [isSending, setIsSending] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    grecaptcha.ready(function() {
      grecaptcha.execute('6LdHAyYcAAAAAKKL8tKdZ_u7aeETcI09DZg-AP11', {action: 'signup'}).then(function(token) {
        if(isSending) return;
        setIsSending(true);
        var body = {
          user: {
            email: form.email.value,
            password: form.password.value,
            password_confirmation: form.passwordConfirmation.value,
          }
        }
        const requestOptions = {
            headers:{ 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(body),
        };
        fetch('/users', requestOptions)
          .then( async (response) => {
            setIsSending(false);
            if(response.ok) {
              const user = await response.json();
              if(user) {
                setUserStorage(user);
                document.getElementsByClassName('grecaptcha-badge')[0].remove()
                if(user.kyc_status === 'verified') window.location.href = '/dashboard';
                else if(user.kyc_status === 'pending') window.location.href = '/dashboard';
                else if(user.kyc_status === 'uncomplete') history.push('/welcome');
                else navigateTo(history, 'signup');
              }
            }else{
              setError('Email or password is wrong');
            }
          }).catch(()=>{
            setError('Email or password wrong');
            setIsSending(false);
          });
      });
    });
  }

  const setField = (event, key) => {
    const formUpdated = setFormField(event, key, form);
    setForm(formUpdated);
    const formValid = validateUser(formUpdated);
    setIsValid(formValid.valid);
    if(!formValid.valid && formValid.message) setError(formValid.message)
    else setError(null);
  }

  return (
    <>
      <div id="signup-form" className="AuthForm">
        <div className="container-full green">
          <div className="container row">
            <section className="col m4 s12">
              <div className="row">
                <div className="col s12">
                  <h2 className="title">Sign up</h2>
                </div>
              </div>
              <div className="row">
                <form className="col s12">
                  <div className="input-row row">
                    <div className="col s12">
                      <label className="col input-label">EMAIL</label>
                      <Input 
                        id="email"
                        form={form}
                        onChange={setField}
                      />
                    </div>
                  </div>
                  <div className="input-row row">
                    <div className="col s12">
                      <label className="col input-label">PASSWORD (6 CHARACTERS MINIMUM)</label>
                      <Input 
                        id="password"
                        form={form}
                        onChange={setField}
                      />
                    </div>
                  </div>
                  <div className="input-row row">
                    <div className="col s12">
                      <Input 
                        id="passwordConfirmation"
                        form={form}
                        onChange={setField}
                      />
                    </div>
                  </div>
                  <div className="input-row row">
                    <div className="col s12">
                      <Checkbox
                        id="checkedTerms"
                        form={form}
                        onChange={setField}
                        />
                    </div>
                  </div>
                  <div className="input-row row">
                    <div className="input-field col s12">
                      <button className="g-recaptcha waves-effect black btn" disabled={!isValid||isSending} onClick={(e)=>{onSubmit(e)}}>
                        Sign up
                      </button>
                      <button className="g-recaptcha waves-effect transparent btn" onClick={(e)=>{history.push('/signin')}}>
                        Log in
                      </button>
                      {error ? (
                        <h6 className="form-error">{error}</h6>
                      ):null}
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </div>
      <AuthStyles/>
    </>
  );
};

export default SignUpForm;

