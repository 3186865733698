import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import TwitterImage from '../../../../assets/images/twitter.png';
import LinkedinImage from '../../../../assets/images/linkedin.png';

const Reports = (props) => {
  const history = useHistory();

  const {center, investor} = props;
  const reports = investor ? [
    {file: '/Abacus Carbono - Q4 2022 Report - Q4202301.pdf', name: 'Download Q4 2022 report'},
    {file: '/Abacus Carbono - Q3 2022 Report - Q2202210.pdf', name: 'Download Q3 2022 report'},
    {file: '/Abacus Carbono - Q2 2022 Report - Q2202207.pdf', name: 'Download Q2 2022 report'},
    {file: '/Abacus Carbono - Q1 2022 Report - Q1202204.pdf', name: 'Download Q1 2022 report'},
    {file: '/Abacus Carbono - Q4 2021 Report - Q4202201.pdf', name: 'Download Q4 2021 report'},
    {file: '/Abacus Carbono - Q3 2021 Report - Q3202110.pdf', name: 'Download Q3 2021 report'},
    {file: '/Abacus Carbono - Q2 2021 Report - Q2202107.pdf', name: 'Download Q2 2021 report'},
  ] : [
    {file: '/Abacus Carbono - Q2 2021 Report (No investors).pdf', name: 'Download Q2 2021 report'},
  ]
  return (
    <>
      <div className={`Reports ${center?'center':''}`}>
        {reports.map((report)=>(
          <div className="report">
            <a href={report.file} target="_blank" className="report-link">
              <div className="material-icons">description</div>
              <span>{report.name}</span>
            </a>
          </div>
        ))}
      </div>
      <style>{`
        .Reports {
          padding-top: 20px;
        }
        .Reports.center {
          padding-top: 0px;
        }
        .Reports.center .report {
          display: inline-block;
        }
        .Reports.center .report-link .material-icons {
          display: block !important;
        }
        .Reports .report-link {
          display: block;
          width: 240px;
          margin: 6px 10px;
          text-align: center;
          color: black;
        }
        .Reports .report-link .material-icons {
          display: inline-block;
          vertical-align: middle;
          margin: 0 auto 10px;
          font-size: 50px;
        }
        .Reports .report-link span {
          vertical-align: middle;
          text-decoration: underline;
        }
      `}</style>
    </>
  );
};

export default Reports;
