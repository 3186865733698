import React, { useState, useEffect } from 'react';

import AbacusCarbonoImage from '../../../../assets/images/abacus-carbono-white.png';
import TwitterImage from '../../../../assets/images/twitter-white.png';
import LinkedinImage from '../../../../assets/images/linkedin-white.png';

const Footer = (props) => {
  const {isHome} = props;
  return (
    <>
      <div className={`Footer ${isHome?'isHome':''}`}>
        <div className="container-full">
          <div className="container row">
            <a href="https://twitter.com/carbono_com" className="social-link">
              <img src={TwitterImage} width={24} height={24} />
            </a>
            <a href="https://www.linkedin.com/company/carbonofund/" className="social-link">
              <img src={LinkedinImage} width={24} height={24} />
            </a>
          </div>
          <div className="container row">
            <img src={AbacusCarbonoImage} width={256} height={22}/>
          </div>
        </div>
      </div>
      <style>
        {`
          .Footer {
            margin-top: 80px;
            text-align: center;
            color: white;
          }
          .Footer.isHome {
            margin-top: 0;
          }
          .Footer .container-full {
            min-height: 300px;
            padding: 100px 0 120px;
            background: black;
          }
          .Footer .social-link {
            display: inline-block;
            margin: 6px 10px;
          }
          .Footer .social-link:first-child {
            margin-left: 6px;
          }
          @media (max-width: 600px) {
            .Footer .container-full {
              min-height: 220px;
              padding: 60px 0 60px;
            }
            .Footer .social-link {
              margin: 6px 25px;
            }
            .Footer .social-link:first-child {
              margin-left: 12px;
            }
          }
        `}
      </style>
    </>
  );
};

export default Footer;

