import React, { useState, useEffect } from 'react';

import Feat1Image from '../../../../assets/images/feature-1.png';
import Feat2Image from '../../../../assets/images/feature-2.png';
import Feat3Image from '../../../../assets/images/feature-3.png';
import Feat4Image from '../../../../assets/images/feature-4.png';
import Feat5Image from '../../../../assets/images/feature-5.png';
import Feat6Image from '../../../../assets/images/feature-6.png';

const Features = () => {
  const features = [{
    "title": "INVEST IN DOLLARS",
    "text": "You won't need to interact with exchanges or protocols. Invest in dollars, get crypto returns. Abacus Carbono offers easy, safe access to crypto.",
    "image": Feat1Image
  },{
    "title": "BANK-GRADE CUSTODY",
    "text": "You don't have to worry about wallets, passwords, keyphrases or cold storage. Abacus Carbono provides state-of-the-art institutional custody solutions for digital assets.",
    "image": Feat2Image
  },{
    "title": "DIVERSIFIED PORTFOLIO",
    "text": "Hedge against the inherent volatility of a crypto with a diversified portfolio. We identify and invest in mid and long term trends that prove a combination of technical and financial solvency, and combine it with a strategy in fiat to provide the safest returns.",
    "image": Feat3Image
  },{
    "title": "EXPERT MANAGEMENT",
    "text": "Work with an experienced team of investors. Our team has been involved in cryptocurrency since 2013, both creating technology and managing millions worth of cryptocurrency assets.",
    "image": Feat4Image
  },{
    "title": "NO LOCK-UP PERIOD",
    "text": "Access your funds at any point in time. Abacus Carbono has no lock-up period so that you can benefit from liquidity, one of the most attractive features of cryptocurrencies.",
    "image": Feat5Image
  },{
    "title": "NO HIDDEN COMMISIONS",
    "text": "Invest dollars, withdraw dollars. Full reimbursement in dollars in your bank account, with zero commissions.",
    "image": Feat6Image
  }];
  return (
    <>
      <div id="features" className="Features">
        <div className="container-full">
          <div className="container row">
            <div className="col s12">
              <h2>Simple, safe access to crypto</h2>
            </div>
            {features.map((feature, index)=>(
            <div key={`feat${index}`} className="feature col m4">
              <b>
                <span className="icon">
                  <img src={feature.image} layout='fill'/>
                </span>
                {feature.title}
              </b>
              <p>{feature.text}</p>
            </div>
            ))}
          </div>
        </div>
      </div>
      <style>
        {`
          .Features .container-full {
            padding-top: 80px;
            padding-left: 0px;
            padding-right: 0px;
          }
          .Features .feature {
            margin-top: 20px;
          }
          .Features b {
            font-weight: 500;
          }
          .Features .icon {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            width: 32px;
            height: 32px;
            margin-right: 4px;
          }
          .Features .icon img {
            width: 32px;
            height: 32px;
          }
          .Features p {
            line-height: 150%;
          }
        `}
      </style>
    </>
  );
};

export default Features;
