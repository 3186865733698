import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from "react-router-dom";

import { getProfileApi, getFilesApi } from './../../requests';
import { validateForm, setFormData } from './../../utils/forms';
import AuthHeader from './../../components/Layout/AuthHeader';
import Reports from './../../components/Layout/Reports';
import { forms } from './../../components/Profile/forms';
import Footer from './../../components/Layout/Footer';
import ProfileStyles from './../../styles/ProfileStyles';

export default () => {
  const history = useHistory();
  const [info, setInfo] = useState(null);

  const isStepComplete = (s, i) => {
    const data = setFormData(s, i);
    return validateForm(data).valid;
  }

  const fetchData = async () => {
    const _info = await getProfileApi();
    const _files = await getFilesApi();
    return {..._info, ..._files};
  }

  const showStep = (data) => {
    const stepsIds = Array.from(Array(8).keys());
    const steps = stepsIds.map((index)=>({
      id: index+1, 
      step: forms[index], 
      isComplete: isStepComplete(forms[index], data)
    }));

    var prevComplete = false;
    for(const id of stepsIds) {
      var completeStep = steps[id].isComplete;
      if(prevComplete && !completeStep) {
        // history.push(steps[id].step.url.value);
        break;
      }
      prevComplete = completeStep;
    }
  }

  useEffect(async () => {
    const data = await fetchData();
    showStep(data);
    setInfo(data);
  }, [])

  return (
    <>
      <main>
      { info ? (
        <>
          <AuthHeader/>
          <div id="signup" className="Profile Welcome">
            <div className="container container-full">
              <div className="row">
                <div className="col s12">
                  <h2 className="title">Welcome to Abacus Carbono</h2>
                </div>
                <div className="col s12">
                  <p>Thank you for signing up. You are just a few steps away from becoming an investor in crypto.</p>
                </div>
                <div className="col s12">
                  <button className="waves-effect black btn" onClick={(e)=>history.push('/signup/1-personal-info')}>INVEST NOW</button>
                </div>
                <div className="col s12">
                  <h3>ABOUT THE INVESTING PROCESS</h3>

                  <p>
                    To become an investor, we need to request specific information. 
                    To make the process easier, you might want to be ready to provide some information and documents:
                  </p>

                  <ul>
                    <li>Complete <b>BANK INFORMATION</b>: bank account number, BIC or SWIFT code, bank score code, and time the account has been held for.</li>
                    <li><b>PASSPORT PICTURE</b>: you can upload one or take one from your computer during the process.</li>
                    <li><b>PROOF OF RESIDENCE</b> document: Commonly accepted proof of residence examples include utility bills 
                      (landline telephone, gas, or electricity not older than three months old), bank account statements, and government authority documents. We don’t accept mobile phone bills.
                    </li>
                  </ul>
                  <p>You will be able to leave the form unfinished at any point in the process and come back to it later.</p>
                  <br/>
                  <b><a className="link" href="/client_agreement.pdf" target="_blank">Read and review the investors contract.</a></b>
                </div>
                <div className="col s12">
                  <h3>DOWNLOAD ABACUS CARBONO QUARTERLY REPORTS</h3>
                  <Reports center={true}/>
                </div>
              </div>
            </div>
          </div>
          <Footer/>
        </>
      ): null}
      </main>
      <ProfileStyles/>
    </>
  );
}
