import React, { useState, useEffect, useMemo } from 'react';

import { Select, Input, Textarea, Radio } from './../../components/Form';

export default (props) => {

  const {single, info, form, setField, save, prev, isValid, isSaved} = props;
  useEffect(async () => {
    if(isSaved) setIsSaving(false)
  }, [isSaved])

  const howMoney = useMemo(() => [
    "Savings From Annual Income",
    "Annual Income",
    "Inheritance",
    "Sale of Other Investment",
    "Other"
  ].map(v=>({value:v, label:v})), [])
  const howLongs = useMemo(() => [
    "0-6 months",
    "6-12 months",
    "1-3 years",
    "3-5 years",
    "More than 5 years"
  ].map(v=>({value:v, label:v})), [])
  const workOrRetired = useMemo(() => [
    "Working",
    "Student",
    "Unemployed",
    "Retired",
  ].map(v=>({value:v, label:v})), [])
  const timeWorking = useMemo(() => [
    "0-6 months",
    "6-12 months",
    "1-3 years",
    "3-5 years",
    "5-10 years",
    "More than 10 years"
  ].map(v=>({value:v, label:v})), [])

  const [isSaving, setIsSaving] = useState(false);
  const onSave = (e) => {
    setIsSaving(true);
    save(e);
  }

  return (
    <form className="row" onSubmit={(event)=>{event.preventDefault()}}>
      <div className="column col s12 m6">
        <div className="row">
          <Select
            id="question_howdidyoumakethemoney"
            form={form}
            options={howMoney}
            onChange={setField}
            />
        </div>
        <div className="row">
          <Textarea
            id="question_howdidyoumakeother"
            form={form}
            onChange={setField}
          />
        </div>
        <div className="row">
          <Select
            id="question_howlonginvest"
            form={form}
            options={howLongs}
            onChange={setField}
            />
        </div>
        <div className="row">
          <Input 
            id="question_amounttoinvest_initial"
            form={form}
            onChange={setField}
          />
        </div>
        <div className="row">
          <Radio
            id="question_workingorretired"
            form={form}
            options={workOrRetired}
            onChange={setField}
            />
        </div>
      </div>
      <div className="column col s12 m6">
        <div className="row">
          <Input 
            id="question_occupation"
            form={form}
            onChange={setField}
          />
        </div>
        <div className="row">
          <Textarea
            id="question_employernameaddress"
            form={form}
            onChange={setField}
          />
        </div>
        <div className="row">
          <Select
            id="question_occupationlength"
            form={form}
            options={timeWorking}
            onChange={setField}
            />
        </div>
        <div className="row">
          <Textarea
            id="question_selfemployedactivity"
            form={form}
            onChange={setField}
          />
        </div>
      </div>
      <div className="footer col s12">
        {!single ? <button className="left waves-effect transparent btn" onClick={(e)=>prev(e)}>Previous</button> : null}
        <button className="right waves-effect black btn" disabled={!isValid||isSaving} onClick={(e)=>onSave(e)}>{single?'Save':'Next'}</button>
      </div>
    </form>
  );
}
