import React from "react";

export default () => (
  <style>{`
    input:not([type]),
    input[type=text]:not(.browser-default),
    input[type=password]:not(.browser-default),
    input[type=email]:not(.browser-default),
    input[type=url]:not(.browser-default),
    input[type=time]:not(.browser-default),
    input[type=date]:not(.browser-default),
    input[type=datetime]:not(.browser-default),
    input[type=datetime-local]:not(.browser-default),
    input[type=tel]:not(.browser-default),
    input[type=number]:not(.browser-default),
    input[type=search]:not(.browser-default),
    textarea.materialize-textarea {
      font-size: 14px;
    }
    input.valid:not([type]), input.valid:not([type]):focus, 
    input.valid[type=text]:not(.browser-default), 
    input.valid[type=text]:not(.browser-default):focus, 
    input.valid[type=password]:not(.browser-default), 
    input.valid[type=password]:not(.browser-default):focus, 
    input.valid[type=email]:not(.browser-default), 
    input.valid[type=email]:not(.browser-default):focus, 
    input.valid[type=url]:not(.browser-default), 
    input.valid[type=url]:not(.browser-default):focus, 
    input.valid[type=time]:not(.browser-default), 
    input.valid[type=time]:not(.browser-default):focus, 
    input.valid[type=date]:not(.browser-default), 
    input.valid[type=date]:not(.browser-default):focus, 
    input.valid[type=datetime]:not(.browser-default), 
    input.valid[type=datetime]:not(.browser-default):focus, 
    input.valid[type=datetime-local]:not(.browser-default), 
    input.valid[type=datetime-local]:not(.browser-default):focus, 
    input.valid[type=tel]:not(.browser-default), 
    input.valid[type=tel]:not(.browser-default):focus, 
    input.valid[type=number]:not(.browser-default), 
    input.valid[type=number]:not(.browser-default):focus, 
    input.valid[type=search]:not(.browser-default), 
    input.valid[type=search]:not(.browser-default):focus, 
    textarea.materialize-textarea.valid, 
    textarea.materialize-textarea.valid:focus, 
    .select-wrapper.valid>input.select-dropdown {
      border-bottom: 1px solid #00FF79;
      -webkit-box-shadow: 0 1px 0 0 #00FF79;
      box-shadow: 0 1px 0 0 #00FF79;
    }
    input:not([type]):focus:not([readonly])+label, 
    input[type=text]:not(.browser-default):focus:not([readonly])+label, 
    input[type=password]:not(.browser-default):focus:not([readonly])+label, 
    input[type=email]:not(.browser-default):focus:not([readonly])+label, 
    input[type=url]:not(.browser-default):focus:not([readonly])+label, 
    input[type=time]:not(.browser-default):focus:not([readonly])+label, 
    input[type=date]:not(.browser-default):focus:not([readonly])+label, 
    input[type=datetime]:not(.browser-default):focus:not([readonly])+label, 
    input[type=datetime-local]:not(.browser-default):focus:not([readonly])+label, 
    input[type=tel]:not(.browser-default):focus:not([readonly])+label, 
    input[type=number]:not(.browser-default):focus:not([readonly])+label, 
    input[type=search]:not(.browser-default):focus:not([readonly])+label, 
    textarea.materialize-textarea:focus:not([readonly])+label {
      color: rgb(0,0,0);
    }
    input:not([type]):focus.valid ~ label, 
    input[type=text]:not(.browser-default):focus.valid ~ label, 
    input[type=password]:not(.browser-default):focus.valid ~ label, 
    input[type=email]:not(.browser-default):focus.valid ~ label, 
    input[type=url]:not(.browser-default):focus.valid ~ label, 
    input[type=time]:not(.browser-default):focus.valid ~ label, 
    input[type=date]:not(.browser-default):focus.valid ~ label, 
    input[type=datetime]:not(.browser-default):focus.valid ~ label, 
    input[type=datetime-local]:not(.browser-default):focus.valid ~ label, 
    input[type=tel]:not(.browser-default):focus.valid ~ label, 
    input[type=number]:not(.browser-default):focus.valid ~ label, 
    input[type=search]:not(.browser-default):focus.valid ~ label, 
    textarea.materialize-textarea:focus.valid ~ label {
      color: #00FF79;
    }
    textarea.materialize-textarea {
      height: 36px;
      min-height: 36px;
    }
    [type="checkbox"]+span:not(.lever),
    .select-wrapper input.select-dropdown {
      font-size: 14px;
    }
    .dropdown-content li {
      min-height: 35px;
    }
    .dropdown-content li > span {
      padding: 5px 16px;
    }
    #toast-container .toast {
      font-size: 12px !important;
    }
  `}</style>
);