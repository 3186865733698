import React from "react";
import SignInForm from './../components/Auth/SignInForm';
import Header from './../components/Layout/Header';

export default () => (
  <>
    <main>
      <Header></Header>
      <SignInForm/>
    </main>
  </>
);
