import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { Input } from './../../components/Form';
import { setFormField } from './../../utils/forms';
import { validateUser } from './../../utils/user';
import AuthStyles from './../../styles/AuthStyles';

const ResetForm = () => {
  const history = useHistory();

  const [form, setForm] = useState({
    email: {value: null, valid: null, required: true, type: 'email', placeholder: 'Your email'},
  })
  const [isSending, setIsSending] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    grecaptcha.ready(function() {
      grecaptcha.execute('6LdHAyYcAAAAAKKL8tKdZ_u7aeETcI09DZg-AP11', {action: 'reset'}).then(function(token) {
        if(isSending) return;
        setIsSending(true);
        var body = {
          user: {
            email: form.email.value
          }
        }
        const requestOptions = {
            headers:{ 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(body),
        };
        fetch('/users/password', requestOptions)
          .then(async (response) => {
            setIsSending(false);
            setSuccess(true);
          }).catch(()=>{
            setError('Some error happened');
            setIsSending(false);
          });

      });
    });
  }

  const setField = (event, key) => {
    const formUpdated = setFormField(event, key, form);
    setForm(formUpdated);
    const formValid = validateUser(formUpdated);
    setIsValid(formValid.valid);
    if(!formValid.valid && formValid.message) setError(formValid.message)
    else setError(null);
  }

  return (
    <>
      <div id="login-form" className="AuthForm">
        <div className="container-full green">
          <div className="container row">
            <section className="col m4 s12">
              <div className="row">
                <div className="col s12">
                  <h2 className="title">Forgot your password?</h2>
                </div>
              </div>
              <div className="row">
                <form className="col s12">
                  <div className="input-row row">
                    <div className="col">
                      <label>Enter your email address and we will send you a link to reset your password.</label>
                    </div>
                  </div>
                  {success ? (
                    <h6 className="form-success">Please, check your email.</h6>
                  ) : (
                    <>
                      <div className="input-row row">
                        <div className="col s12">
                          <Input 
                            id="email"
                            form={form}
                            onChange={setField}
                          />
                        </div>
                      </div>
                      <div className="input-row row">
                        <div className="input-field col s12">
                          <button className="g-recaptcha waves-effect black btn" disabled={!isValid||isSending} onClick={(e)=>{onSubmit(e)}}>
                            Send password reset email
                          </button>
                          <button className="g-recaptcha waves-effect transparent btn" onClick={(e)=>{history.push('/signin')}}>
                            Log in
                          </button>
                          {error ? (
                            <h6 className="form-error">{error}</h6>
                          ):null}
                        </div>
                      </div>
                    </>
                  )}
                </form>
              </div>
            </section>
          </div>
        </div>
      </div>
      <AuthStyles/>
    </>
  );
};

export default ResetForm;

