import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from "react-router-dom";

import { getProfileApi, setProfileApi, getFilesApi, saveFilesApi, getUserApi } from './../requests';
import { validateForm, setFormData, getFormData, setFormField } from './../utils/forms';
import { setUserStorage } from './../utils/storage';

import AuthHeader from './../components/Layout/AuthHeader';
import Personal from './../components/Profile/Personal';
import Professional from './../components/Profile/Professional';
import Bank from './../components/Profile/Bank';
import Suitability from './../components/Profile/Suitability';
import Passport from './../components/Profile/Passport';
import Residence from './../components/Profile/Residence';
import Risk from './../components/Profile/Risk';
import Agreement from './../components/Profile/Agreement';
import Password from './../components/Profile/Password';
import StartInvesting from './../components/Profile/StartInvesting';
import { forms } from './../components/Profile/forms';
import Footer from './../components/Layout/Footer';

import ProfileStyles from './../styles/ProfileStyles';

export default () => {
  const history = useHistory();
  const location = useLocation();

  const [user, setUser] = useState(null)
  const [title, setTitle] = useState(null)
  const [info, setInfo] = useState(null)
  const [form, setForm] = useState(null)
  const [stepIndex, setStepIndex] = useState(0)
  const [step, setStep] = useState(null)

  const [isValid, setIsValid] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  useEffect( async () => {
    const _user = await fetchUser();
    const _stepIndex = startedInvesting(_user) ? 0 : 9;
    if(_stepIndex) setTitle("Start investing");
    constructForm(_stepIndex);
  }, [location.pathname])

  const fetchUser = async () => {    
    const user = await getUserApi();
    setUserStorage(user);
    setUser(user);
    return user;
  }

  const constructForm = async (index) => {
    if(forms[index]) {
      const info = await getProfileApi();
      const files = await getFilesApi();
      const data = {...info, ...files};
      const formData = setFormData(forms[index], data);
      const formValid = validateForm(formData).valid;
      setIsValid(formValid);
      setTitle(formData.title.value);
      setForm(formData);
      setInfo(data);
    }
    setStep(index)
  }

  const goToPrev = () => {}

  const save = async (event) => {
    setIsSaved(false);
    event.preventDefault();
    await setProfileApi(getFormData(form));
    if(M) M.toast({html: 'The changes have been saved'});
    setIsSaved(true);
  }

  const upload = async (event) => {
    setIsSaved(false);
    event.preventDefault();
    const formData = new FormData();
    if(form.document_icpassport) {
      const document_icpassport = form.document_icpassport.value;
      if(document_icpassport && typeof document_icpassport !== 'string') formData.append('document_icpassport', document_icpassport, document_icpassport.name);
    }
    if(form.document_addressproof) {
      const document_addressproof = form.document_addressproof.value;
      if(document_addressproof && typeof document_addressproof !== 'string') formData.append('document_addressproof', document_addressproof);
    }
    await saveFilesApi(formData);
    if(M) M.toast({html: 'The changes have been saved'});
    setIsSaved(true);
  }

  const setField = (event, key) => {
    const formUpdated = setFormField(event, key, form);
    setForm(formUpdated);
    const formValid = validateForm(formUpdated).valid;
    setIsValid(formValid);
  }

  const getStep = (index) => {
    switch(index) {
      case 0:
        return <Personal single={true} form={form} info={info} setField={setField} save={save} isValid={isValid} isSaved={isSaved} />
      case 1:
        return <Professional single={true} form={form} info={info} setField={setField} save={save} prev={goToPrev} isValid={isValid} isSaved={isSaved} />
      case 2:
        return <Bank single={true} form={form} info={info} setField={setField} save={save} prev={goToPrev} isValid={isValid} isSaved={isSaved} />
      case 3:
        return <Suitability single={true} form={form} info={info} setField={setField} save={save} prev={goToPrev} isValid={isValid} isSaved={isSaved} />
      case 4:
        return <Passport single={true} form={form} info={info} setField={setField} save={upload} prev={goToPrev} isValid={isValid} isSaved={isSaved} />
      case 5:
        return <Residence single={true} form={form} info={info} setField={setField} save={upload} prev={goToPrev} isValid={isValid} isSaved={isSaved} />
      case 6:
        return <Risk single={true} form={form} info={info} setField={setField} save={save} prev={goToPrev} isValid={isValid} isSaved={isSaved} />
      case 7:
        return <Agreement single={true} form={form} info={info} setField={setField} save={save} prev={goToPrev} isValid={isValid} isSaved={isSaved} />
      case 8:
        return <Password />
      case 9:
        return <StartInvesting/>
    }
  }

  const changeStep = (index, _title) => {
    if(_title) setTitle(_title);
    setStepIndex(index);
    constructForm(index);
  }

  const startedInvesting = (_user) => {
    return _user && _user.kyc_status !== 'unverified' && _user.kyc_status !== 'uncomplete' ;
  }

  const isContentReady = (step, stepIndex, form, info) => {
    if(step === 8) return true;
    if(step === 9) return true;
    if(step === stepIndex && form && info) return true;
    return false;
  }

  

  return (
    <>
      <main>
        <AuthHeader/>
          <div id="signup" className="Profile">
            <div className="container container-full container-sidebar">
              {user ? (
                <div className="row">
                  <div className="col s1 m1 l3 sidebar">
                    <h5 className="hide-on-med-and-down">Investor profile</h5>
                    { startedInvesting(user) ? forms.map((form, index)=>(
                      <a key={`sbTitle_${index}`} onClick={()=>{changeStep(index)}}>
                        <i className="material-icons" dangerouslySetInnerHTML={{__html: form.title.icon}}></i>
                        <span className="hide-on-med-and-down">{form.title.value}</span>
                      </a>
                    )) : (
                      <a key={`sbTitle`} className="black" onClick={()=>{changeStep(9, "Start investing")}}>
                        <span className="hide-on-med-and-down">Start investing</span>
                      </a>
                    )}
                    <h5 className="hide-on-med-and-down">Security</h5>
                    <a onClick={()=>{changeStep(8, "Change password")}}>
                      <i className="material-icons">&#xe897;</i>
                      <span className="hide-on-med-and-down">Change password</span>
                    </a>
                  </div>
                  <div className="col s11 m11 l9 content">
                    { isContentReady(step, stepIndex, form, info) ? (
                      <>
                        <h2 className="title">{title}</h2>
                        {getStep(step)}
                      </>
                    ) : null }
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <Footer/>
      </main>
      <ProfileStyles/>
    </>
  );
}
