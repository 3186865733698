import React, { useState, useEffect, useMemo } from 'react';

import { Radio, Textarea, FilePicker } from './../../components/Form';

export default (props) => {
  
  const {single, info, form, setField, save, prev, isValid, isSaved} = props;
  useEffect(async () => {
    if(isSaved) setIsSaving(false)
  }, [isSaved])

  const yesOrNo = useMemo(() => [
    "Yes",
    "No",
  ].map(v=>({value:v, label:v})), [])

  const [isSaving, setIsSaving] = useState(false);
  const onSave = (e) => {
    setIsSaving(true);
    save(e);
  }

  return (
    <form className="row" onSubmit={(event)=>{event.preventDefault()}}>
      <div className="col s12">
        <div className="row">
          <p>Please upload suitable and clear verification documents.</p>
          <small>Commonly accepted proof of residence examples include utility bills (landline telephone, 
            gas or electricity &lt; 3 months old, mobile phone bills are not accepted),
            bank account statements, government authority documents...
          </small><br/><br/>
        </div>
        <div className="row">
          <FilePicker
            id="document_addressproof"
            form={form}
            onChange={setField}
            />
        </div>
      </div>
      <div className="footer col s12">
        {!single ? <button className="left waves-effect transparent btn" onClick={(e)=>prev(e)}>Previous</button> : null}
        <button className="right waves-effect black btn" disabled={!isValid||isSaving} onClick={(e)=>onSave(e)}>{single?'Save':'Next'}</button>
      </div>
    </form>
  );
}
