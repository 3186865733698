import React, { useState, useEffect } from 'react';

import { format } from 'date-fns';

import Reports from './../../components/Layout/Reports';
import AssetsList from './../../components/Charts/AssetsList';
import IndexChart from './../../components/Charts/IndexChart';
import KrakenSockets from './../../components/Utils/KrakenSockets';
import DashboardStyles from './../../styles/DashboardStyles';
import { getBalancesApi, getAssetsApi, getAssetsCoingecko, getHistoricalCoingecko } from './../../requests';
import { decryptBalances } from './../../utils/utils';

let balanceUpdated = 0;

const Investor = (props) => {

  const formatBalance = (balance) => {
    return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0}).format(balance)
  }
  const [assets, setAssets] = useState(null);
  const [assetsPrices, setAssetsPrices] = useState(null);
  const [balances, setBalances] = useState(null);
  const [updated, setUpdated] = useState(props.updated)
  const [balance, setBalance] = useState(props.balance)
  const [unitPrice, setUnitPrice] = useState(null)
  const [units, setUnits] = useState(props.units)

  useEffect( async () => {
    getBalances();
    getAssets();
  }, []);

  const getBalances = async (user) => {
    const response = await getBalancesApi();
    const balances = decryptBalances(response.balances);
    const balancesWithUSD = balances.map(a=>({...a, ...{quantity:0,price:(a.asset.includes("USD")?1:0)}}))
    setBalances(balancesWithUSD);
  }

  const getAssets = async () => {
    const response = await getAssetsApi();
    if(response.assets) getAssetsInfo(response.assets)
  }

  const getAssetsInfo = async (assets) => {
    const assetsInfo = await getAssetsCoingecko(assets);
    setAssets(assetsInfo);
    for(const index in assetsInfo) {
      const prices = await getHistoricalCoingecko(assetsInfo[index]);
      assetsInfo[index].prices = prices.prices;
      assetsInfo[index].price_change_month = assetsInfo[index].current_price - prices.prices[0][1];
      for(const price of prices.prices) {
        if(isOlderThanWeek(price[0])){
          assetsInfo[index].price_change_week = assetsInfo[index].current_price - price[1];
          break;
        }
      }
      setAssets(assetsInfo);
    }
  }

  const isOlderThanWeek = (date) => {
    var d = new Date();
    d.setDate(d.getDate() - 7);
    d.setHours(0, 0, 0, 0);
    return new Date(date)>new Date(d);
  }

  const onUpdateAssets = (updatedAssets) => {
    const now = () => new Date().getTime();
    if(now() - balanceUpdated > 1000) {
      if(!updatedAssets.filter(asset=>!asset.price).length) {
        let bal = 0;
        updatedAssets.forEach((asset, index)=>{
          bal += updatedAssets[index].amount * updatedAssets[index].price * units;
        })
        setUpdated(-1)
        setUnitPrice(bal / units);
        setBalance(bal);
      }
      setAssetsPrices([...updatedAssets]);
      balanceUpdated = now();
    }
  }
  return (
    <>
      <div className="Dashboard">
        <div className="container container-full container-sidebar">
          {balances ? (
            <div className="row">
              <div className="col s12">
                <h4>
                  <i className="material-icons">&#xe853;</i>
                  <span>Your balance: {formatBalance(balance)}</span>
                </h4>
                <b>Abacus Carbono Performance</b>
                <IndexChart isHome={false} unitPrice={unitPrice} />
                {updated ? (
                  <small className="last-update right">
                    {updated === -1 ? (
                      <span>Real time data</span>
                    ) : (
                      <span>Last update: {format(new Date(updated), 'yyyy-MM-dd HH:mm')}</span>
                    )}
                  </small>
                ) : null}
              </div>
              <div className="row">
                <div className="col s12">
                  <h4>
                    <i className="material-icons">&#xe2c8;</i>
                    <span>Reports</span>
                  </h4>
                  <Reports investor={true}/>
                </div>
              </div>
              {assets ? (
                <div className="row">
                  <div className="col s12">
                    <AssetsList assets={assets} assetsPrices={assetsPrices} />
                  </div>
                </div>
              ) : null}
              {balances ? <KrakenSockets assets={balances} onUpdateAssets={onUpdateAssets}/> : null}
            </div>
          ): null}
        </div>
      </div>
      <DashboardStyles/>
    </>
  );
};

export default Investor;