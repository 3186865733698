
export const faqs = [
  {
    'title': 'General',
    'questions': [
      {
        'question': 'What is Abacus Carbono?',
        'answer': '<p>Abacus Carbono is an investment fund specialized in cryptocurrencies. Our goal is to help investors get exposure to the crypto ecosystem in a convenient, transparent and secure way. Investors make their contributions in dollars, and it is the fund who chooses the investments, carries out the trading operations and custodies the assets.</p>'
      },
      {
        'question': 'What is our investment philosophy?',
        'answer': `<p>Cryptocurrencies are one of the most interesting assets on the technological and financial landscape and they have an enormous appeal for investors interested in keeping up with innovation. At Abacus Carbono offer a gateway for these investors into the cryptocurrency ecosystem. And we make this happen by offering a product where profitability and security are in balance. Abacus Carbono seeks the most profitable, safest returns. Our investment philosophy is characterized by two features:</p>
        <ul>
          <li>The design of a diversified portfolio of cryptocurrencies. Our work consists of identifying medium and long-term trends - DeFi protocols, Layer 1 or Layer 2 solutions, etc.-, and looking into them in search for those projects that best combine technological and financial solvency.</li>
          <li>The continuous balance between fiat investment (USD) and cryptocurrencies, depending on the state of the market at all times, in order to minimize risks and maximize opportunities.</li>
        </ul>`
      },
      {
        'question': 'How is the portfolio selected?',
        'answer': `<p>Abacus Carbono has a list of pre-approved cryptocurrencies, selected with the supervision of the regulator in charge of our investment license. The management team can add assets to that list through a vetting process that involves the participation of an investment committee, made up of a combined team from Carbono and Farringdon AM,  risk and investment officers, and the Farringdon A.M. leadership.</p>
        <p>The criteria for the inclusion of a new cryptocurrency are:</p>
        <ul>
          <li>Liquidity. A sufficient volume is available in the exchanges approved by Abacus Carbono.</li>
          <li>Technical relevance. The token's value proposition fits inside the trends identified as relevant for the future in the crypto ecosystem.</li>
          <li>Reputation. Project history, team and safety criteria meet the required standards and ensure the reliability of each possible investment.</li>
        </ul>`
      },
      {
        'question': 'How can I become an investor?',
        'answer': `<p>Abacus Carbono is a regulated fund: investors have to go through a standard KYC identity verification process to register on the platform.</p>`
      },
      {
        'question': 'How can I know the status of my investment?',
        'answer': `<p>Investors can access a private area where they can check their balance and the performance of the pre-approved assets in real time. For any additional questions, you can contact our team through <a href="mailto:info@carbono.com">info@carbono.com</a>.</p>`
      },
      {
        'question': 'Under what license does Abacus Carbon operate?',
        'answer': `<p>The Abacus Carbono license is owned by Farringdon Asset Management Ltd., which holds a Fund Manager license in Labuan, Malaysia that enables the investment team to offer innovative financial services.</p>`
      },
    ]
  }, {
    'title': 'Contributions',
    'questions': [
      {
        'question': 'What is the minimum investment?',
        'answer': `<p>The minimum investment for individual investors is $10,000.<br/>Additional contributions must also reach the amount of $10,000.<br/>For institutional investors, the minimum investment is $100,000.</p>`
      },
      {
        'question': 'How long does it take for a contribution to be invested?',
        'answer': `<p>The process of receiving funds, and converting them to critpo-assets can take between 5 and 9 days. This timeline includes the registration verification process, the standard deadlines for bank transfers and the investment registration deadline.</p>`
      }
    ]
  }, {
    'title': 'Refunds',
    'questions': [
      {
        'question': 'Can I withdraw my investment whenever I want?',
        'answer': `<p>Yes. Abacus Carbono does not have a lock-up period, but a one-week notice is required to make the reimbursement. The reimbursement price will be established in the 24-48 hours following the moment of notice.</p>`
      },
      {
        'question': 'What is the refund process?',
        'answer': `<p>After the reimbursement request, Abacus Carbono begins the liquidation of the invested assets assigned to the investor, proportional to their contribution, and makes a deposit in dollars in the bank account provided by the investor at the time of registration.</p>
        <p>The sale price is blocked in the 24-48 hours following the moment of notice.</p>`
      },
      {
        'question': 'The sale price is blocked in the 24-48 hours following the moment of notice.',
        'answer': `<p>The minimum partial refund must be of at least $10,000, and an amount equal to or greater than $10,000 must remain invested.</p>`
      }
    ]
  }, {
    'title': 'Commissions',
    'questions': [
      {
        'question': 'Is there any kind of subscription or redemption fee?',
        'answer': `<p>No, Abacus Carbono does not charge any subscription or redemption fees.</p>`
      },
      {
        'question': 'What are the fees for Abacus Carbono?',
        'answer': `<p>A management fee of 2.5% per year on the total invested, charged quarterly on a pro-rata basis (0.625% each quarter).</p>
        <p>A 20% performance fee, prorated quarterly, with high-water mark.</p>`
      },
      {
        'question': 'What is the high-water mark?',
        'answer': `<p>It is the measure that guarantees that an investor does not pay twice for the same benefits. The high-water mark states that the performance fee can only be applied when the fund is above a previously achieved level.</p>`
      }
    ]
  }, {
    'title': 'Security',
    'questions': [
      {
        'question': 'How are the assets custodied?',
        'answer': `<p>Abacus Carbono uses with BitGo for the custody of digital assets. BitGo is a leading custody and trading platform, established in the US, and founded in 2013 with the purpose of offering legal and technological security to institutional clients.</p>
        <p>BitGo has the SOC 2 certification for cybersecurity risk control, is a pioneer in the implementation of multi-signature validation systems, and offers insurance in case of theft or technical problem worth $100 million.</p>`
      },
      {
        'question': 'What cybersecurity measures does Abacus Carbono have?',
        'answer': `<p>Cryptoasset management with Bitgo requires a multi-signature authentication process that involves the participation of three parties. It includes an identity validation by videoconference, which prevents a single person from making any movement of cryptocurrencies. BitGo is a pioneer and leader in its sector in security and custody measures for institutional clients.</p>
        <p><a href="https://www.bitgo.com/services/custody/qualified-custody">https://www.bitgo.com/services/custody/qualified-custody</a></p>`
      }
    ]
  }, {
    'title': 'Taxes',
    'questions': [
      {
        'question': 'How are investments in cryptocurrencies taxed?',
        'answer': `<p>Each country has a different fiscal regulation for capital income. In most cases, investors pay only for the revenue obtained after redemption of their investments.</p>
        <p>Abacus Carbono manages all investments once the initial contribution is made, which in many countries means that purchase or sale operations inside the fund are not taxable.</p>`
      }
    ]
  }
]