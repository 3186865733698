import React, { useState, useEffect } from 'react';

const Newsletter = () => {
  return (
    <>
      <div className="Newsletter">
        <div className="container-full">
          <div className="container row">
            <div className="col s12 newsletter">
              <iframe className="iframe" src={"https://carbono.substack.com/embed"} width={640} height={320} frameBorder={0} scrolling={"no"}></iframe>
              <div className="iframefoot"></div>
            </div>
          </div>
        </div>
      </div>
      <style>
        {`
          .Newsletter .newsletter {
            width: 100%;
            margin: 20px auto 0;
            background: white;
          }
          .Newsletter .iframe {
            display: block;
            width: 640px;
            max-width: 100%;
            margin: auto;
          }
          .Newsletter .iframefoot {
            position: absolute;
            left: 50vw;
            width: 50vw;
            height: 30px;
            margin-top: -30px;
            background: white;
            z-index: 1111;
          }
        `}
      </style>
    </>
  );
};

export default Newsletter;

