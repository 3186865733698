import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import Newsletter from './../../components/Layout/Newsletter';
import DashboardStyles from './../../styles/DashboardStyles';

const Pending = () => {
  const history = useHistory();

  return (
    <>
      <div className="Dashboard Pending">
        <div className="container container-full">
          <div className="row">
            <div className="col s12">
              <h2 className="title">Thank you, we are reviewing your profile.</h2>
              <p>
                Thanks for submitting the information. Our compliance team will now review your profile. 
              </p>
              <p>
                We will contact you in as soon as possible to confirm the submission and provide instructions on how to proceed with the investment through bank transfer.
              </p>
              <p>
                If you need to review or edit your information, <a onClick={()=>history.push('/profile')} className="link">click here</a>.
              </p>
              <p>
                While you wait, you can follow us on <a href="https://twitter.com/carbono_com" target="_blank" className="link">Twitter</a> and <a href="https://www.linkedin.com/company/carbonofund" target="_blank" className="link">LinkedIn</a>, and subscribe to our newsletter:
              </p>
            </div>
          </div>
        </div>
        <Newsletter/>
      </div>
      <DashboardStyles/>
      <style>{`
        .Dashboard {
          text-align: center;
        }
      `}</style>
    </>
  );
};

export default Pending;

