import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';

import { Camera } from './Camera';

const FilePicker = (props) => {

  const [uploaded, setUploaded] = useState();
  const [selected, setSelected] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isCameraActive, setIsCameraActive] = useState(false);

  const {id, form, onChange} = props;
  const data = form && form[id] ? form[id] : null;
  const required = data.required !== undefined ? data.required : true;
  const label = data.label !== undefined ? data.label : null;
  const sublabel = data.sublabel !== undefined ? data.sublabel : null;
  const value = data.value ? data.value : '';
  const camera = data.camera ? data.camera : null;

  useEffect(() => {
    setUploaded(value);
  }, []);

  const changeHandler = (event) => {
    const file = event.target.files[0];
    onChange({target:{validity:{valid:true},value:file}}, id);
    setSelected(file);
    setIsFilePicked(true);
  }

  const captureHandler = (image) => {
    image.name = `Captured image ${format(new Date(), 'yyyy-MM-dd HH:mm')}`
    onChange({target:{validity:{valid:true},value:image}}, id);
    setSelected(image);
    setIsFilePicked(true);
    setIsCameraActive(false);
  }

  const getLabel = (label) => {
    return label ? <label className="active" htmlFor={id}>{label} {required?<sup>*</sup>:null}</label> : null;
  }

  const getFile = () => {
    return (
      <button className="left selectfile waves-effect black btn">
        Select a file <input type="file" name={id} onChange={changeHandler} />
      </button>
    );
  }

  const getSelected = (selected) => {
    return selected ? <span className="selected">{selected.name}</span> : null;
  }

  const getUploaded = (uploaded) => {
    return uploaded ? <div className="uploaded"><p>Uploaded file: {uploaded}</p></div> : null;
  }

  const getCamera = (camera, isCameraActive) => {
    if(!camera) return null;
    if(camera && isCameraActive) return <Camera sendFile={captureHandler} close={()=>setIsCameraActive(false)} />;
    return <button className="left btn black set-camera" onClick={()=>{setIsCameraActive(true)}}><span className="material-icons">add_a_photo</span></button>;
  }

  return id ? (
    <div className={`input-field file-field`}>
      {getLabel(label)}
      {getFile()}
      {getCamera(camera, isCameraActive)}
      {getSelected(selected)}
      {getUploaded(uploaded)}
      <style>{`
        .file-field label {
          position: relative;
          display: block;
          margin: 10px 0 0;
          font-size: 12px;
        }
        .file-field .selectfile {
          margin: 0 10px 20px 0;
          float: none;
        }
        .file-field .uploaded {
          margin-top: 10px;
          clear: both;
        }
        .file-field .selected {
          margin-left: 6px;
          line-height: 48px;
        }
        .file-field .set-camera {
          padding: 0 12px !important;
          color: white;
          font-size: 42px;
          line-height: 10px;
        }
      `}</style>
    </div>
  ) : null;
}
export {FilePicker};