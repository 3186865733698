
const userConstant = 'user';

export function getUserStorage() {
  return JSON.parse(localStorage.getItem(userConstant));
}
export function setUserStorage(user) {
  return localStorage.setItem(userConstant, JSON.stringify(user));
}
export function deleteUserStorage() {
  return localStorage.removeItem(userConstant);
}