import React, { useState, useEffect } from 'react';

import IconImage from '../../../../assets/images/icon.png';
import DashboardStyles from './../../styles/DashboardStyles';

const Withdrawn = (props) => {
  return (
    <>
      <div className="Dashboard">
        <div className="container container-full container-sidebar">
          <div className="row">
            <div className="col s12">
              <div className="bye">
                <img className="center" src={IconImage} width={24} height={24}/>
                <h4 className="center">Thank you</h4>
                <a className="center" href="mailto:abacus@carbono.com">abacus@carbono.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DashboardStyles/>
    </>
  );
};

export default Withdrawn;