import React from "react";
import SignUpForm from './../../components/Auth/SignUpForm';
import Header from './../../components/Layout/Header';

export default () => (
  <>
    <main>
      <Header></Header>
      <SignUpForm/>
    </main>
  </>
);
