import React, { useEffect } from 'react';

const Checkbox = (props) => {

  const {id, form, values, onChange} = props;
  const data = form && form[id] ? form[id] : null;
  const required = data.required !== undefined ? data.required : true;
  const label = data.label !== undefined ? data.label : null;
  const value = data.value ? data.value : '';

  return id ? (
    <div className={`checkbox-field`}>
      <div key={id} className="checkbox-button">
        <label>
          <input name={id} type="checkbox"
          className="validate"
          onKeyDown={(e) => {e&&e.code==='Enter'&&e.preventDefault()}}
          onChange={(e)=>onChange({target:{validity:{valid:true},value:!value}}, id)}
          checked={value}
          required={required}
          />
          <span dangerouslySetInnerHTML={{__html: label}}></span>
        </label>
      </div>
      <style>{`
        .checkbox-label {
          display: block;
          width: 100%;
          height: 30px;
        }
        .checkbox-button {
          display: inline-flex;
          padding-top: 10px;
          padding-right: 10px;
        }
      `}</style>
    </div>
  ) : null;
}
export {Checkbox};