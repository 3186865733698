import React, { useState, useEffect, useMemo } from 'react';

import { FilePicker } from './../../components/Form';

export default (props) => {

  const {single, info, form, setField, save, prev, isValid, isSaved} = props;
  useEffect(async () => {
    if(isSaved) setIsSaving(false)
  }, [isSaved])

  const [isSaving, setIsSaving] = useState(false);
  const sign = async (event) => {
    setIsSaving(true);
    event.preventDefault();
    var formEl = document.createElement('form');
    document.body.appendChild(formEl);
    formEl.method = 'post';
    formEl.action = '/sign';
    formEl.submit();
  }

  return (
    <form className="row Agreement" onSubmit={(event)=>{event.preventDefault()}}>
      {form && form.document_clientagreement && form.document_clientagreement.value ? (
        <div className="col s12">
          <p>
            Read the <a href="client_agreement.pdf" target="_blank" className="link">client agreement.</a>
          </p>
        </div>
      ) : (
        <>
          <div className="col s12">
            <div className="row">
              <p>Finally, we ask you to review and sign our Client agreement contract</p>
            </div>
          </div>
          <div className="footer col s12">
            {!single ? <button className="left waves-effect transparent btn" onClick={(e)=>prev(e)}>Previous</button> : null}
            <button className="right waves-effect black btn" disabled={isSaving} onClick={(e)=>sign(e)}>Read & Sign</button>
            <small className="right info">You will be redirected to Docusign.</small>
          </div>
        </>
      )}
      <style>{`
        .Agreement .info {
          display: block;
          width: 100%;
          margin: 10px 0;
          text-align: right;
        }
      `}</style>
    </form>
  );
}
