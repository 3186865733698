import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { getUserStorage } from './../../utils/storage';
import AbacusCarbonoImage from '../../../../assets/images/abacus-carbono.png';

const Header = (props) => {
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState();

  useEffect(async () => {
    const user = getUserStorage();
    setCurrentUser(user);
  }, [])

  const navigate = () => {
    if(currentUser.role === 'admin' || currentUser.role=== 'reviewer' ) window.location.href = '/admin';
    else if(currentUser.kyc_status === 'verified') window.location.href = '/dashboard';
    else if(currentUser.kyc_status === 'pending') window.location.href = '/dashboard';
    else if(currentUser.kyc_status === 'uncomplete') history.push('/welcome');
    else window.location.href="/dashboard"
  }

  const {menu} = props;
  return (
    <>
      <div className="Header">
        <nav className="container row transparent">
          <div className="col s12 nav-wrapper transparent">
            <a onClick={()=>history.push('/')} className="brand-logo">
              <img src={AbacusCarbonoImage} width={256} height={22}/>
            </a>
            { menu ? (
              <>
                {currentUser ? (
                  <button className="right waves-effect black btn" onClick={()=>navigate()}>
                    {currentUser.kyc_status==='verified'?'Dashboard':'My profile'}
                  </button>
                ) : (
                  <button className="right waves-effect black btn" onClick={()=>history.push('/signin')}>Log in</button>
                )}
                <ul id="nav-mobile" className="right hide-on-med-and-down">
                  <li><a onClick={()=>location.hash='#features'} className="black-text">Features</a></li>
                  <li><a onClick={()=>location.hash='#key-facts'} className="black-text">Key facts</a></li>
                  {/*<li><a onClick={()=>location.hash='#philosophy'} className="black-text">Philosophy</a></li>*/}
                  <li><a onClick={()=>location.hash='#contact-us'} className="black-text">Contact us</a></li>
                  <li><a onClick={()=>history.push('/faqs')} className="black-text">FAQs</a></li>
                </ul>
              </>
            ):null}
          </div>
        </nav>
      </div>
      <style>
        {`
          .Header .container {
            transform: translateX(-50%);
            position: absolute;
            top: 0;
            left: 50vw;
            padding-top: 10px;
            box-shadow: none;
          }
          .Header nav .nav-wrapper {
            width: 100%;
          }
          .Header nav .brand-logo {
            font-size: 14px;
            cursor: pointer;
          }
          .Header nav .brand-logo img {
            max-width: 70vw;
            height: auto;
          }
          .Header .btn {
            margin-top: 12px;
            margin-left: 12px;
            padding: 0 32px;
            font-weight: 300;
            text-transform: capitalize;
          }
          @media (max-width: 600px) {
            .Header .btn {
              margin-top: 16px;
              padding: 0 16px;
              font-size: 12px;
            }
            .Header nav .brand-logo {
              transform: translate(0, 10px);
              left: 12px;
            }
            .Header nav .brand-logo img {
              max-width: 60vw;
            }
          }
        `}
      </style>
    </>
  );
};

export default Header;
