import React, { useState, useEffect, useMemo } from 'react';
import countryList from 'react-select-country-list'

import { Select, Input, Textarea, DatePicker, PhoneSelector } from './../../components/Form';

export default (props) => {

  const {single, info, form, setField, save, prev, isValid, isSaved} = props;
  useEffect(async () => {
    if(isSaved) setIsSaving(false)
  }, [isSaved])

  const countries = useMemo(() => countryList().getData(), [])
  const maritalStatuses = useMemo(() => [
    "Single", 
    "Married", 
    "Divorced", 
    "Widowed"
  ].map(v=>({value:v, label:v})), [])

  const [isSaving, setIsSaving] = useState(false);
  const onSave = (e) => {
    setIsSaving(true);
    save(e);
  }

  return (
    <form className="row" onSubmit={(event)=>{event.preventDefault();event.stopPropagation();}}>
      <div className="column col s12 m6">
        <div className="row">
          <Input 
            id="full_name"
            form={form}
            onChange={setField}
          />
        </div>
        <div className="row">
          <DatePicker
            id="date_of_birth"
            form={form}
            onChange={setField}
            />
        </div>
        <div className="row">
          <Textarea
            id="address_residential"
            form={form}
            onChange={setField}
          />
        </div>
        <div className="row">
          <Textarea
            id="address_correspondence"
            form={form}
            onChange={setField}
          />
        </div>
        <div className="row">
          <PhoneSelector
            id="phone_number"
            form={form}
            onChange={setField}
            />
        </div>
      </div>
      <div className="column col s12 m6">
        <div className="row">
          <Input 
            id="tax_code"
            form={form}
            onChange={setField}
          />
        </div>
        <div className="row">
          <Select
            id="country_residence"
            form={form}
            options={countries}
            onChange={setField}
            />
        </div>
        <div className="row">
          <Select
            id="country_nationality"
            form={form}
            options={countries}
            onChange={setField}
            />
        </div>
        <div className="row">
          <Input 
            id="ic_passport_no"
            form={form}
            onChange={setField}
          />
        </div>
        <div className="row">
          <Select
            id="marital_status"
            form={form}
            options={maritalStatuses}
            onChange={setField}
            />
        </div>
      </div>
      <div className="footer col s12">
        <button className="right waves-effect black btn" disabled={!isValid||isSaving} onClick={(e)=>onSave(e)}>{single?'Save':'Next'}</button>
      </div>
    </form>
  );
}
