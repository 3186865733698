import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { Input } from './../../components/Form';
import { getUserStorage, setUserStorage } from './../../utils/storage';
import { setFormField } from './../../utils/forms';
import { validateUser } from './../../utils/user';
import { getParamFromUrl, navigateTo } from './../../utils/utils';
import AuthStyles from './../../styles/AuthStyles';

const SignInForm = () => {
  const history = useHistory();

  const [form, setForm] = useState({
    email: {value: null, valid: null, required: true, type: 'email', placeholder: 'Your email'},
    password: {value: null, valid: null, required: true, type: 'password', minLength: 6, placeholder: 'Your password'},
  })
  const [isSending, setIsSending] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const user = getUserStorage();
    if(user) goToNext();
  }, []);

  const goToNext = () => {
    const next = getParamFromUrl('next');
    if(next) {
      if(next.includes("admin")) return window.location.href = next;
      else return history.push(next);
    }
    return false;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    grecaptcha.ready(function() {
      grecaptcha.execute('6LdHAyYcAAAAAKKL8tKdZ_u7aeETcI09DZg-AP11', {action: 'signin'}).then(function(token) {
        if(isSending) return;
        setIsSending(true);
        var body = {
          user: {
            email: form.email.value,
            password: form.password.value
          }
        }
        const requestOptions = {
            headers:{ 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(body),
        };
        fetch('/users/sign_in', requestOptions)
          .then(async (response) => {
            setIsSending(false);
            if(response.ok) {
              const user = await response.json();
              if(user) {
                setUserStorage(user);
                document.getElementsByClassName('grecaptcha-badge')[0].remove();
                if(!goToNext()) {
                  if(user.role === 'admin' || user.role=== 'reviewer' ) window.location.href = '/admin';
                  else if(user.kyc_status === 'verified') window.location.href = '/dashboard';
                  else if(user.kyc_status === 'pending') window.location.href = '/dashboard';
                  else if(user.kyc_status === 'uncomplete') history.push('/welcome');
                  else navigateTo(history, 'signup');
                }
              }
            }else{
              setError('Email or password wrong');
            }
          }).catch(()=>{
            setError('Email or password wrong');
            setIsSending(false);
          });
      });
    });
  }

  const setField = (event, key) => {
    const formUpdated = setFormField(event, key, form);
    setForm(formUpdated);
    const formValid = validateUser(formUpdated);
    setIsValid(formValid.valid);
    if(!formValid.valid && formValid.message) setError(formValid.message)
    else setError(null);
  }

  return (
    <>
      <div id="signin-form" className="AuthForm">
        <div className="container-full green">
          <div className="container row">
            <section className="col m4 s12">
              <div className="row">
                <div className="col s12">
                  <h2 className="title">Log in</h2>
                </div>
              </div>
              <div className="row">
                <form className="col s12">
                  <div className="input-row row">
                    <div className="col s12">
                      <Input 
                        id="email"
                        form={form}
                        onChange={setField}
                      />
                    </div>
                  </div>
                  <div className="input-row row">
                    <div className="col s12">
                      <Input 
                        id="password"
                        form={form}
                        onChange={setField}
                      />
                    </div>
                  </div>
                  <div className="input-row row">
                    <div className="input-field col s12">
                      <button className="g-recaptcha waves-effect black btn" disabled={!isValid||isSending} onClick={(e)=>{onSubmit(e)}}>
                        Log in
                      </button>
                      <button className="g-recaptcha waves-effect transparent btn" onClick={(e)=>{history.push('/signup')}}>
                        Sign up
                      </button>
                      {error ? (
                        <h6 className="form-error">{error}</h6>
                      ):null}
                    </div>
                  </div>
                  <div className="input-row row">
                    <div className="input-field col s12">
                      <button className="g-recaptcha waves-effect link transparent btn" onClick={(e)=>{history.push('/reset')}}>
                        Forgot your password?
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </div>
      <AuthStyles/>
    </>
  );
};

export default SignInForm;

