import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { getProfileApi } from './../../requests';
import { getParamFromUrl } from './../../utils/utils';
import DashboardStyles from './../../styles/DashboardStyles';

const Verified = () => {
  const history = useHistory();
  const [profile, setProfile] = useState(null);

  useEffect(async () => {
    const email = getParamFromUrl('email');
    const profile = await getProfileApi(email);
    setProfile(profile);
  }, [])

  return profile ? (
    <>
      <div className="Dashboard Pending">
        <div className="container container-full">
          <div className="row">
            <div className="col s12">
              <h2 className="title">Welcome on board, {profile.full_name}. </h2>
              <p>We are happy to confirm that your application has successfully completed our internal compliance.</p>
              <p>Your account will be activated once the transfer of funds has been finalised. </p>
              <br/>
              <p><u>Transfer instructions</u></p>
              <p>Your plan number is: {profile.reference_code}.</p> This number should be used as your reference number for your bank transfer.
              <p>The account will be activated once the transfer has been paired with your proof of payment.</p>
              <p>Please bank transfer the required amount to the below bank details within 7 working days.</p>
              <br/>
              <table>
                <tr>
                  <td><b>Account Name:</b></td>
                  <td><b>Farringdon Asset Management LTD</b></td>
                </tr>
                <tr>
                  <td><b>Bank Name:</b></td>
                  <td><b>OCBC Bank</b></td>
                </tr>
                <tr>
                  <td><b>Bank Address:</b></td>
                  <td><b>18 Jalan Tun Perak, KLCC, 50050, Kuala Lumpur, Malaysia</b></td>
                </tr>
                <tr>
                  <td><b>USD Account Number:</b></td>
                  <td><b>701-155630-4</b></td>
                </tr>
                <tr>
                  <td><b>Swift Code:</b></td>
                  <td><b>OCBCMYKL</b></td>
                </tr>
                <tr>
                  <td><b>Reference Number:</b></td>
                  <td><b>{profile.reference_code}</b></td>
                </tr>
              </table>
              <p>
                Please note that <b><u>FULL</u></b> account name must be entered otherwise the receiving bank will refuse the transfer. 
                If the full name will not fit in the online field, please contact your bank to ensure the full name is manually entered to avoid any unnecessary delays. 
                Before we can apply your money to your account, we require a screen print of your transfer, 
                a transfer receipt or a bank statement detailing the transfer.
              </p>
              <p><b><u>Please ensure that the transfer clearly states you name, account number and the transfer amount.</u></b></p>
              <p>Your Abacus Account Access is available 24 hours a day at our web portal.</p>
              <p>Please contact us at <a href="mailto:compliance@carbono.com">compliance@carbono.com</a> if you require any help in registering at our website.</p>
              <p>Thank you for choosing Abacus Carbono. We are committed to providing the best service and support to help you plan for your future.</p>
            </div>
          </div>
        </div>
      </div>
      <DashboardStyles/>
      <style>{`
        .Dashboard {
          text-align: center;
        }
        .Dashboard table{
          width: 480px;
          max-width: 100%;
          margin: auto;
        }
      `}</style>
    </>
  ) : null;
};

export default Verified;

