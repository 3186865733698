import React, { useState, useRef, useEffect } from 'react';

const Camera = (props) => {

  const [imagePreview, setImagePreview] = useState(null);
  const [streaming, setStreaming] = useState(null);
  const canvasRef = useRef();
  const videoRef = useRef();
  /**
   * On mount, grab the users connected devices and process them
   * @memberof CameraFeed
   * @instance
   * @override
   */
  useEffect( async() => {
    const cameras = await navigator.mediaDevices.enumerateDevices();
    processDevices(cameras);
  }, []);

  /**
  * Processes available devices and identifies one by the label
  */
  const processDevices = (devices) => {
    devices.forEach(device => {
      setDevice(device);
    });
  }

  /**
   * Sets the active device and starts playing the feed
   */
  const setDevice = async (device) => {
    const { deviceId } = device;
    const stream = await navigator.mediaDevices.getUserMedia({ audio: false, video: { deviceId } });
    videoRef.current.srcObject = stream;
    videoRef.current.play();
    setStreaming(stream)
  }

  /**
   * Handles taking a still image from the video feed on the camera
   * @memberof CameraFeed
   * @instance
   */
  const takePhoto = () => {
    const context = canvasRef.current.getContext('2d');
    context.drawImage(videoRef.current, 0, 0, 960, 720);
    setImagePreview(true);
  };
  const cancelPhoto = () => {
    setImagePreview(false);
  };
  const setPhoto = () => {
    const { sendFile } = props;
    canvasRef.current.toBlob(sendFile);
    closeCamera();
  };
  const closeCamera = () => {
    const {close} = props;
    if(streaming) {
      streaming.getTracks().forEach(function(track) {
        track.stop();
      });
    }
    close();
  };

  return (
    <div className="Camera camera-container">
      <div className="camera-content">
        <header>
          <button className="right btn transparent" onClick={()=>closeCamera()}>
            <span className="material-icons">clear</span>
          </button>
        </header>
        <div className="camera-viewer">
          <video ref={videoRef} width="480" heigh="360" />
          <div className={`camera-stage ${imagePreview?'visible':''}`}>
            <canvas width="960" height="720" ref={canvasRef} />
          </div>
        </div>
        <div className="row">
          {imagePreview ? (
            <>
              <div className="col s6">
                <button className="take-picture btn black" onClick={()=>cancelPhoto()}>Take another</button>
              </div>
              <div className="col s6">
                <button className="take-picture btn black" onClick={()=>setPhoto()}>Set photo</button>
              </div>
            </>
          ) : (
            <div className="col s12">
              <button className="take-picture btn black" onClick={()=>takePhoto()}>Take photo</button>
            </div>
          )}
        </div>
      </div>
      <style>{`
        .Camera.camera-container {
          position: fixed;
          width: 100vw;
          height: 100vh;
          top: 0px;
          left: 0px;
          z-index: 10;
        }
        .Camera.camera-container:before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          background: rgba(0,0,0,0.1);
        }
        .Camera header {
          height: 48px;
          font-size: 36px;
        }
        .Camera header button {
          line-height: 60px !important;
        }
        .Camera .camera-content {
          transform: translateX(-50%);
          position: absolute;
          width: 520px;
          max-width: calc(100vw - 40px);
          top: 52px;
          left: 50%;
          height: 520px;
          background: white;
        }
        .Camera .camera-viewer {
          position: relative;
          width: calc(100% - 40px);
          height: 360px;
          margin: auto;
        }
        .Camera .camera-viewer video {
          display: block;
          max-width: 100%;
          margin: 0 auto;
        }
        .Camera .camera-viewer .camera-stage {
          position: absolute;
          top: 0px;
          left: 0px;
          opacity: 0;
          z-index: 10;
        }
        .Camera .camera-viewer .camera-stage canvas{
          max-width: 100%;
        }
        .Camera .camera-viewer .camera-stage.visible {
          opacity: 1;
        }
        .Camera .take-picture {
          display: block;
          width: calc(100% - 40px);
          margin: 20px auto;
          float: none;
          font-size: 14px;
        }
      `}</style>
    </div>
  );
}
export {Camera};