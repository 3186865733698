import React, { useState, useEffect, useMemo } from 'react';

import { Select, Input, Textarea } from './../../components/Form';

export default (props) => {

  const {single, info, form, setField, save, prev, isValid, isSaved} = props;
  useEffect(async () => {
    if(isSaved) setIsSaving(false)
  }, [isSaved])

  const accountYears = useMemo(() => [
    "0","1","2","3","4","5","6","7","8","9","10","+10"
  ].map(v=>({value:v+' years', label:v+' years'})), [])
  const accountMonths = useMemo(() => [
    "0","1","2","3","4","5","6","7","8","9","10","11"
  ].map(v=>({value:v+' months', label:v+' months'})), [])

  const [isSaving, setIsSaving] = useState(false);
  const onSave = (e) => {
    setIsSaving(true);
    save(e);
  }

  return (
    <form className="row" onSubmit={(event)=>{event.preventDefault()}}>
      <div className="column col s12 m6">
        <div className="row">
          <Input
            id="question_bank_name"
            form={form}
            onChange={setField}
            />
        </div>
        <div className="row">
          <Textarea
            id="question_bank_address"
            form={form}
            onChange={setField}
          />
        </div>
        <div className="row">
          <Input
            id="question_bank_accountname"
            form={form}
            onChange={setField}
            />
        </div>
        <div className="row">
          <Input
            id="question_bank_swift"
            form={form}
            onChange={setField}
            />
        </div>
      </div>
      <div className="column col s12 m6">
        <div className="row">
          <Input
            id="question_bank_sortcode"
            form={form}
            onChange={setField}
            />
        </div>
        <div className="row">
          <Input
            id="question_bank_ibanornumber"
            form={form}
            onChange={setField}
            />
        </div>
        <div className="row">
          <Select
            id="question_bank_accountyears"
            form={form}
            options={accountYears}
            onChange={setField}
            />
        </div>
        <div className="row">
          <Select
            id="question_bank_accountmonths"
            form={form}
            options={accountMonths}
            onChange={setField}
            />
        </div>
      </div>
      <div className="footer col s12">
        {!single ? <button className="left waves-effect transparent btn" onClick={(e)=>prev(e)}>Previous</button> : null}
        <button className="right waves-effect black btn" disabled={!isValid||isSaving} onClick={(e)=>onSave(e)}>{single?'Save':'Next'}</button>
      </div>
    </form>
  );
}
