import React, { useState, useEffect, useRef } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from "highcharts/highstock";

const MiniChart = (props) => {
  const chartRef = useRef()
  const {data} = props;

  const [chartSeries, setChartSeries] = useState(null);
  const [chartOptions, setChartOptions] = useState({
    title: { text: ''},
    credits: { enabled: false},
    legend: { enabled: false},
    chart: {
      type: 'scatter',
      animation: true,
      panning: {enabled: false},
      backgroundColor: 'rgba(255, 255, 255, 0)',
      spacingTop: 0,
      spacingRight: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      plotBorderWidth: 0,
      margin: [0,0,0,0]

    },
    plotOptions: {
      bar: {
        borderWidth: 2
      },
      series: {
        turboThreshold: 100000,
      }
    },
    xAxis: {
      visible: false,
      title: {text: ''},
      type: 'datetime',
      ordinal: false,
      dateTimeLabelFormats: {
        month: '%e. %b',
        year: '%b'
      },
      gridLineColor : 'white',
      labels: {
          style: {
              color: 'white',
              fontSize: '7'
          }
      }
    },
    yAxis: {
      visible: false,
      title: {
        text: ''
      },
      labels: {
          style: {
              color: 'white',
              fontSize: '7'
          }
      },
      gridLineColor : 'white'
    },
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false
    },
    rangeSelector:{
      enabled:false,
    },
    tooltip: {
      enabled: false,
    },
    series: []
  });

  useEffect(() => {
    if(data) setChartData(data)
  }, [data]);

  const setChartData = async () => {
    const series = [{ 
      type: 'line',
      lineWidth: 1,
      lineColor: 'black',
      name: 'Asset',
      data: data
    }]
    setChartOptions({series});
    setChartSeries(series);
  }

  return (
    <>
      <div className="MiniChart">
        <HighchartsReact
          ref={chartRef}
          highcharts={Highcharts}
          options={chartOptions}
          constructorType={"stockChart"}
        />
      </div>
      <style>
        {`
          .MiniChart,
          .MiniChart > * {
            height: 40px;
          }
        `}
      </style>
    </>
  );
};

export default MiniChart;

