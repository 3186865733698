import React from "react";
import ResetPasswordForm from './../components/Auth/ResetPasswordForm';
import Header from './../components/Layout/Header';

export default () => (
  <>
    <main>
      <Header></Header>
      <ResetPasswordForm/>
    </main>
  </>
);
