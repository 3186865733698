import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Layout from './components/Layout';
import Home from "./pages/Home";
import Faqs from "./pages/Faqs";
import SignIn from "./pages/SignIn";
import Reset from "./pages/Reset";
import ResetPassword from "./pages/ResetPassword";
import history from "./history";

import SignUp from "./pages/SignUp/index";
import SignUpWelcome from './pages/SignUp/SignUpWelcome'
import SignUpStep from './pages/SignUp/SignUpStep'
import Dashboard from './pages/Dashboard'
import Profile from './pages/Profile'

export default (
    <Router history={history}>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/faqs" exact component={Faqs} />
        <Route path="/signin" exact component={SignIn} />
        <Route path="/users/sign_in" exact component={SignIn} />
        <Route path="/reset" exact component={Reset} />
        <Route path="/reset/password" exact component={ResetPassword} />
        <Route path="/signup" exact component={SignUp} />
        <Route path="/users/sign_up" exact component={SignUp} />
        <Route path="/welcome" exact component={SignUpWelcome} />
        <Route path="/signup/1-personal-info" exact component={SignUpStep} />
        <Route path="/signup/2-professional-info" exact component={SignUpStep} />
        <Route path="/signup/3-bank-info" exact component={SignUpStep} />
        <Route path="/signup/4-additional-info" exact component={SignUpStep} />
        <Route path="/signup/5-passport" exact component={SignUpStep} />
        <Route path="/signup/6-proof-residence" exact component={SignUpStep} />
        <Route path="/signup/7-risk" exact component={SignUpStep} />
        <Route path="/signup/8-agreement" exact component={SignUpStep} />
        <Route path="/dashboard" exact component={Dashboard} />
        <Route path="/profile" exact component={Profile} />
      </Switch>
    </Router>
);
