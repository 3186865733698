import React, { useState, useEffect } from 'react';

const KrakenSockets = (props) => {

  const {assets, onUpdateAssets} = props;
  const [socket, setSocket] = useState(null);
  const [isSocketOpen, setIsSocketOpen] = useState(null);

  useEffect( async () => {
    openSockets();
  }, []);

  const openSockets = () => {
    if (window.WebSocket) {
      const _socket = new WebSocket("wss://ws.kraken.com/");
      _socket.onopen = function() {
        setIsSocketOpen(true);
        sendSocketMessages(_socket);
      };
      _socket.onclose = function() {
        setIsSocketOpen(false);
      };
      _socket.onmessage = function(message) {
        useSocketMessage(message)
      };
      setSocket(_socket);
    }
  }
  const sendSocketMessages = (socket) => {
    for(const asset of assets) {
      if (!asset.asset.includes("USD")) {
        var socketAsset = convertAssetForSocket(asset.asset);
        var message = '{"event":"subscribe", "subscription":{"name":"book"}, "pair":["'+socketAsset+'/USD"]}';
        if (message !== "") socket.send(message);
      }
    }
  }
  const useSocketMessage = (message) => {
    const data = JSON.parse(message.data);
    if(data && data.length) {
      const socketAsset = data[3].split('/USD')[0];
      const asset = convertAssetForWeb(socketAsset);
      const values = data[1];
      if(values && values.a) {
        const price = Number(values.a[0][0]);
        const updateAsset = assets.find(a=>a.asset === asset);
        if(updateAsset) {
          updateAsset.price = price;
          onUpdateAssets(assets);
        }
      }
    }
  }
  const convertAssetForSocket = (asset) => {
    return asset === 'BTC' ? 'XBT' : asset;
  }
  const convertAssetForWeb = (asset) => {
    return asset === 'XBT' ? 'BTC' : asset;
  }
  return null;
};

export default KrakenSockets;