import React, { useState, useEffect, useRef } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from "highcharts/highstock";

import MiniChart from './MiniChart';
import { minify } from '../../utils/utils';

const AssetsList = (props) => {
  const chartRef = useRef()
  const {assetsPrices} = props;

  const [assets, setAssets] = useState(props.assets);
  const [period, setPeriod] = useState();

  useEffect(() => {
    updateFullAssets(assetsPrices);
  }, [assetsPrices]);

  useEffect(() => {
    if(period && assets) {
      const d = getPeriodDate();
      const alterAssets = assets.map((asset)=>{
        if(asset.prices) {
          asset.periodPrices = asset.prices.filter((price)=>(new Date(price[0])>new Date(d)));
        }
        return asset;
      })
      setAssets(alterAssets);
    }
  }, [period]);

  const getPeriodDate = () => {
    var d = new Date();
    switch(period) {
      case '24H':
        d.setDate(d.getHours() - 24);
        break;
      case '1W':
        d.setDate(d.getDate() - 7);
        d.setHours(0, 0, 0, 0);
        break;
      case '1M':
        d.setMonth(d.getMonth() - 1);
        d.setHours(0, 0, 0, 0);
        break;
    }
    return d;
  }

  const updateFullAssets = async (assetsPrices) => {
    if(assets && assetsPrices) {
      const updatedAssets = assets.map((asset)=>{
        const assetPrice = assetsPrices.find(a=>a.asset === asset.symbol);
        if(assetPrice) asset.current_price = assetPrice.price;
        return asset;
      })
      setAssets(updatedAssets);
    }
  }

  const openInNewTab = (id) => {
    const url = `https://www.coingecko.com/en/coins/${id}`;
    window.open(url, '_blank').focus();
  }

  return (
    <>
      <div className="AssetsList">
        <div className="row">
          <div className="col s12">
            <h4>
              <i className="material-icons">&#xe6c4;</i>
              <span>Cryptocurrencies approved by Abacus Carbono’s Investment Committee</span>
            </h4>
          </div>
        </div>
        <div className="row hide-on-small-only">
          <div className="period-bar">
            <button className={`period ${period==='24H'?'active':''}`} onClick={()=>{setPeriod('24H')}}>24H</button>
            <button className={`period ${period==='1W'?'active':''}`} onClick={()=>{setPeriod('1W')}}>1W</button>
            <button className={`period ${!period||period==='1M'?'active':''}`} onClick={()=>{setPeriod('1M')}}>1M</button>
          </div>
        </div>
        <div className="row assets">
          <div className="col s12">
            <div className="row">
              <div className="col s9 m6 l3 inline">
                <span>Asset</span>
              </div>
              <div className="col s4 m4 l4 hide-on-small-only">
                <span className="">Evolution</span>
              </div>
              <div className="col l1 hide-on-med-and-down">
                <span className="right">24H</span>
              </div>
              <div className="col l1 hide-on-med-and-down">
                <span className="right">1W</span>
              </div>
              <div className="col l1 hide-on-med-and-down">
                <span className="right">1M</span>
              </div>
              <div className="col l1 hide-on-med-and-down market_cap">
                <span className="right">M. Cap</span>
              </div>
              <div className="col s3 m2 l1">
                <span className="right">Price</span>
              </div>
            </div>
          {assets ? assets.map((asset, index)=>(
            <div className="row">
              <div className="col s9 m6 l3 inline coinlink" onClick={()=>openInNewTab(asset.id)}>
                <span className="id">{index+1}</span>
                <img className="img" src={asset.image}/>
                <b className="symbol">{asset.symbol}</b>
                <span className="name">{asset.name}</span>
              </div>
              <div className="col s4 m4 l4 hide-on-small-only minichart">
                <div>{asset.prices ? <MiniChart data={asset.periodPrices||asset.prices}/> : null}</div>
              </div>
              <div className={`col l1 hide-on-med-and-down ${asset.price_change_24h<0?'negative':'positive'}`}>
                <span className="right price">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 2}).format(asset.price_change_24h)}</span>
              </div>
              <div className={`col l1 hide-on-med-and-down ${asset.price_change_week<0?'negative':'positive'}`}>
                {asset.price_change_week ? <span className="right price">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 2}).format(asset.price_change_week)}</span> : null}
              </div>
              <div className={`col l1 hide-on-med-and-down ${asset.price_change_month<0?'negative':'positive'}`}>
                {asset.price_change_month ? <span className="right price">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 2}).format(asset.price_change_month)}</span> : null}
              </div>
              <div className="col l1 hide-on-med-and-down market_cap">
                <span className="right price">${minify(asset.market_cap)}</span>
              </div>
              <div className="col s3 m2 l1">
                <span className="right price">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0}).format(asset.current_price)}</span>
              </div>
            </div>
          )) : null}
          </div>
        </div>
      </div>
      <style>{`
        .AssetsList {
          font-size: 12px;
        }
        .AssetsList .assets .row {
          margin-top: 0;
          margin-bottom: 0;
          padding-top: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid #f1f1f1;
        }
        .AssetsList .col.inline {
          display: inline-flex;
          align-items: center;
        }
        .AssetsList .coinlink {
          cursor: pointer;
        }
        .AssetsList .id {
          width: 30px;
        }
        .AssetsList .symbol {
          width: 80px;
          font-size: 16px;
        }
        .AssetsList .market_cap {
          padding-right: 10px;
        }
        .AssetsList .name {
          width: calc(100% - 118px);
        }
        .AssetsList .img {
          display: inline-block;
          width: 30px;
          height: 30px;
          margin-right: 24px;
        }
        .AssetsList .minichart {
          height: 40px;
        }
        .AssetsList .positive {
          color: green;
        }
        .AssetsList .negative {
          color: red;
        }
        .AssetsList .period-bar {
          padding-right: 0;
          text-align: right;
        }
        .AssetsList .period-bar .period {
          padding: 2px 10px;
          background: none;
          border: none;
        }
        .AssetsList .period-bar .period.active {
          background: black;
          color: white;
        }
      `}
      </style>
    </>
  );
  //   </>
  // );
}

export default AssetsList;

