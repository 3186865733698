import React, { useEffect } from 'react';
import Hero from './../components/Home/Hero';
import Features from './../components/Home/Features';
import IndexChart from './../components/Charts/IndexChart';
import KeyFacts from './../components/Home/KeyFacts';
import ContactUs from './../components/Home/ContactUs';
import Team from './../components/Home/Team';
import Footer from './../components/Layout/Footer';
import Newsletter from './../components/Home/Newsletter';
import Header from './../components/Layout/Header';
import { getUserApi } from './../requests';
import { setUserStorage } from './../utils/storage';

export default () => {

  useEffect(() => {
    fetchUser();
  }, [])

  const fetchUser = async () => {    
    const user = await getUserApi();
    if(user) setUserStorage(user);
  }

  return (
    <>
      <main>
        <Header menu={true}></Header>
        <Hero/>
        <Features/>
        <IndexChart isHome={true}/>
        <KeyFacts/>
        <ContactUs/>
        <Team/>
        <Newsletter/>
        <Footer isHome={true}/>
      </main>
    </>
  );
}
