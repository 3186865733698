import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { getUserStorage } from './../../utils/storage';
import IllustrationImage from '../../../../assets/images/illustration.png';

const Hero = () => {
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState();

  useEffect(async () => {
    const user = getUserStorage();
    setCurrentUser(user);
  }, [])

  const navigate = (user) => {
    if(!user) history.push('/signup');
    else if(user.kyc_status === 'pending') window.location.href = '/dashboard';
    else history.push('/welcome');
  }

  return (
    <>
      <div className="Hero">
        <div className="container-full green">
          <div className="container row">
            <section className="col m5">
              <h1>Invest in crypto without the hassle</h1>
              <p>
                No wallets, no keys, no need to learn the trade...
                Abacus Carbono is an investment fund designed to be the gateway for investors interested in cryptocurrencies
              </p>
              <div>
                <button onClick={()=>navigate(currentUser)} className="left waves-effect black btn">INVEST NOW</button>
                {/*<a href="" target="_blank" className="left waves-effect transparent btn">FACTSHEET</a>*/}
              </div>
            </section>
            <section className="col m7">
              <div className="illustration">          
                <img src={IllustrationImage} width={480} height={480}/>
              </div>
            </section>
          </div>
        </div>
      </div>
      <style>
        {`
          .Hero .container-full {
            margin-bottom: 40px;
            padding: 180px 0 120px;
          }
          .Hero .col {
            position: relative;
          }
          .Hero p {
            margin-bottom: 30px;
          }
          .Hero .btn {
            height: 48px;
            padding: 0 24px;
            line-height: 48px;
          }
          .Hero .illustration {
            position: absolute;
            left: 0;
            margin-top: 64px;
          }
          .Hero .illustration img {
            width: 640px;
            max-width: 100%;
            height: auto;
            margin-top: 40px;
            margin-left: 40px;
          }
          @media (max-width: 600px) {
            .Hero .container-full {
              margin-bottom: 0;
              padding-top: 80px;
              padding-bottom: 0;
            }
            .Hero .illustration {
              position: relative;
              height: 0;
              margin-top: 40px;
              padding-bottom: 46%;
            }
            .Hero .illustration img {
              width: 90%;
              margin: 0;
              margin-bottom: -100px;
              margin-left: 5%;
            }
        `}
      </style>
    </>
  );
};

export default Hero;

