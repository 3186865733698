import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

const Newsletter = (props) => {
  const history = useHistory();

  return (
    <>
      <div className="Newsletter">
        <div className="col s12">
          <div className="">
            <div className="col s12 newsletter">
              <iframe className="iframe" src={"https://carbono.substack.com/embed"} width={640} height={320} frameBorder={0} scrolling={"no"}></iframe>
              <div className="iframefoot"></div>
            </div>
          </div>
        </div>
      </div>
      <style>{`
        .Newsletter {
          text-align: center !important;
        }
        .Newsletter .newsletter {
          width: 100%;
          margin: -20px auto 0;
          background: white;
        }
        .Newsletter .iframe {
          display: block;
          width: 640px;
          max-width: 100%;
          margin: auto;
        }
        .Newsletter .iframefoot {
          position: absolute;
          left: 50vw;
          width: 50vw;
          height: 30px;
          margin-top: -30px;
          background: white;
          z-index: 1111;
        }
      `}</style>
    </>
  );
};

export default Newsletter;
