
export const isEnv = (env) => {
  if(env==="production" && location.hostname === "abacus.carbono.com") return true;
  if(env==="staging" && location.hostname === "abacus-staging.carbono.com") return true;
  if(env==="local" && location.hostname === "localhost") return true;
  return false;
}

export const navigateTo = (history, page) => {
  switch(page) {
    case 'signup':
      // isEnv('production') ? (window.location.href = '/individual/1-personal-info') : (history.push('/welcome'))
      history.push('/welcome')
      break;
    default:
      break;
  }
}

export const getParamFromUrl = (key) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
}

export const decryptBalances = (data) => {
  data = atob(data.slice(2));
  const assets = [];
  const encodedAssets = data.split(':');
  for(const asset of encodedAssets) {
    let parts = atob(asset).split(':');
    if(parts.length === 2) assets.push({asset: parts[0].slice(2), amount: parseFloat(parts[1])})
  }
  return assets;
}

export const assetForCoingecko = (asset) => {
  switch(asset) {
    case 'BTC': return 'bitcoin';
    case 'ETH': return 'ethereum';
    case 'XTZ': return 'tezos';
    case 'USDT': return 'tether';
    case 'MKR': return 'maker';
    case 'DAI': return 'dai';
    case 'ATOM': return 'cosmos';
    case 'REP': return 'augur';
    case 'BCH': return 'bitcoin-cash';
    case 'EOS': return 'eos';
    case 'XMR': return 'monero';
    case 'ZEC': return 'zcash';
    case 'USDC': return 'usd-coin';
    case 'GNO': return 'gnosis';
    case 'COMP': return 'compound-governance-token';
    case 'KNC': return 'kyber-network-crystal';
    case 'SNX': return 'havven';
    case 'BAL': return 'balancer';
    case 'DOT': return 'polkadot';
    case 'UNI': return 'uniswap';
    case 'AAVE': return 'aave';
    case 'PERP': return 'perpetual-protocol';
    case 'LUNA': return 'terra-luna';
    case 'FTT': return 'aave';
    case 'SOL': return 'ftx-token';
    case 'DYDX': return 'dydx';
    case 'LDO': return 'lido-dao';
    default: return asset;
  }
}

export const minify = (amount) => {
  if (!amount) {
    return '0';
  } else {
    let money;
    let suffix = '';
    money = amount = parseFloat(amount);
    if(Math.abs(amount) >= 1000 && Math.abs(amount) < 1000000) {
      money = parseFloat((money / 1000).toString());
      suffix = 'k';
    }
    if(Math.abs(amount) >= 1000000 && Math.abs(amount) < 1000000000) {
      money = parseFloat((money / 1000000).toString());
      suffix = 'M';
    }
    if(Math.abs(amount) >= 1000000000 && Math.abs(amount) < 1000000000000) {
      money = parseFloat((money / 1000000000).toString());
      suffix = 'B';
    }
    if(Math.abs(amount) >= 1000000000000) {
      money = parseFloat((money / 1000000000000).toString());
      suffix = 'T';
    }
    money = parseInt((money*100).toString(), 10)/100;
    return `${money} ${suffix}`
  }
}

