import React from "react";

export default () => (
  <style>{`
    .highcharts-html-tooltip {
      border: 1px solid #000;
      background: #fff;
      display: block;
      padding: 0 5px !important;
      width: 180px;
      min-width: 180px;
      white-space: normal !important;
    }

    .highcharts-label, 
    .highcharts-tooltip-box {
      min-width: 180px;
    }
  `}</style>
);