import React from "react";

export default () => (
  <style>{`
    .Dashboard .container-full {
      min-height: 60vh;
      padding-top: 120px;
      padding-left: 0px;
      padding-right: 0px;
    }
    .Dashboard.Pending .container-full {
      padding-top: 240px;
    }
    .Dashboard .title {
      margin-top: 0;
      padding-left: 10px;
    }
    .Dashboard h4 .material-icons {
      vertical-align: middle;
      margin-right: 6px;
    }
    .Dashboard h4 span {
      vertical-align: top;
    }
    .Dashboard .bye {
      margin-top: 80px;
    }
    .Dashboard .bye * {
      display: block;
      margin: 15px auto 0;
    }
    .Dashboard .bye a {
      color: black;
      text-decoration: underline;
    }
    .Dashboard .last-update {
      margin-right: 20px;
      margin-top: -20px;
    }
  `}</style>
);