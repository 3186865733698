import React, { useEffect } from 'react';

const Input = (props) => {

  const {id, form, onChange} = props;
  const data = form && form[id] ? form[id] : null;
  const label = data.label !== undefined ? data.label : null;
  const placeholder = data.placeholder !== undefined ? data.placeholder : null;
  const type = data.type !== undefined ? data.type : 'text';
  const required = data.required !== undefined ? data.required : true;
  const minLength = data.minLength !== undefined ? data.minLength : true;
  const min = data.min !== undefined ? data.min : null;
  const max = data.max !== undefined ? data.max : null;
  const value = data.value ? data.value : '';

  return id ? (
    <div className={`input-field`}>
      <input id={id}
        type={type}
        className="validate"
        value={value}
        onKeyDown={(e) => {e&&e.code==='Enter'&&e.preventDefault()}}
        onChange={(e) => {onChange(e, id)}}
        placeholder={placeholder}
        min={min}
        max={max}
        required={required} />
      {label?<label className="active" htmlFor={id}>{label} {required ? <sup>*</sup> : null}</label>:null}
    </div>
  ) : null;
}
export {Input};