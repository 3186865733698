import React from "react";
import Routes from "./routes";
import GlobalStyles from './styles/GlobalStyles';

export default () => (
  <>
    {Routes}
    <GlobalStyles/>
  </>
);
