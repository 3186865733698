import React, { useEffect, useRef} from 'react';
import IntlTelInput from 'react-intl-tel-input';
import PhoneSelectorStyles from './../../styles/PhoneSelectorStyles';

const PhoneSelector = (props) => {

  const {id, form, onChange} = props;
  const data = form && form[id] ? form[id] : null;
  const required = data.required !== undefined ? data.required : true;
  const label = data.label !== undefined ? data.label : null;
  const value = data.value ? data.value : '';

  const intlTelInputRef = useRef();

  const onPhoneChange = (v) => {
    if(intlTelInputRef) {
      const {current} = intlTelInputRef;
      if(current) {
        const {tel, selectedCountryData} = current;
        if(tel && selectedCountryData) {
          const {dialCode} = selectedCountryData;
          const {value} = tel;
          const phone = {target: {validity:{valid:true},value: `+${dialCode} ${value}`}};
          onChange(phone, id);
        }
      }
    }
  }

  return id ? (
    <div className={`input-field`}>
      <IntlTelInput
        defaultValue={value}
        formatOnInit={true}
        preferredCountries={['']}
        initialCountry={'auto'}
        separateDialCode={true}
        nationalCode={false}
        onPhoneNumberChange={onPhoneChange}
        ref={intlTelInputRef}
      />
      <label className="active" htmlFor={id}>{label} {required?<sup>*</sup>:null}</label>
      <style>{`
        .intl-tel-input .selected-flag .iti-flag {
          margin-top: 12px;
          margin-left: 12px;
        }
        .intl-tel-input .selected-dial-code {
          padding-left: 0 !important;
        }
        .intl-tel-input .arrow {
          display: none;
        }
        .intl-tel-input input {
          text-indent: 10px;
        }
      `}</style>
      <PhoneSelectorStyles/>
    </div>

  ) : null;
}
export {PhoneSelector};