import React, { useEffect } from 'react';

const Select = (props) => {

  const {id, form, onChange, options} = props;
  const data = form && form[id] ? form[id] : null;
  const required = data.required !== undefined ? data.required : true;
  const label = data.label !== undefined ? data.label : null;
  const placeholder = data.placeholder !== undefined ? data.placeholder : 'Choose your option';
  const value = data.value ? data.value : '';

  useEffect(() => {
    setTimeout(function(){
      const M = window.M;
      if(M) {
        M.FormSelect.init(document.querySelectorAll('select'), {});
      }
    })
  }, []);

  return id ? (
    <div className={`input-field validate`}>
      <select id={id}
        className="validate"
        value={value}
        onChange={(e) => {onChange(e, id)}}
        required={required} >
        <option value="" disabled>{placeholder}</option>
        {options.map((option,index)=><option key={`${id}_${index}`} value={option.value}>{option.label}</option>)}
      </select>
      {label ? <label>{label} {required ? <sup>*</sup>:null}</label> : null}
    </div>
  ) : null;
}
export {Select};