import React, { useState, useEffect } from 'react';

const ContactUs = () => {
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [congrats, setCongrats] = useState(false);
  const onSubmit = (e) => {
    e.preventDefault();
    grecaptcha.ready(function() {
      grecaptcha.execute('6LdHAyYcAAAAAKKL8tKdZ_u7aeETcI09DZg-AP11', {action: 'contactUs'}).then(function(token) {
        const data = new URLSearchParams();
        data.append('email', email);
        data.append('message', message);
        const requestOptions = {
            method: 'POST',
            body: data
        };
        fetch('https://formspree.io/mbjwdbnq', requestOptions)
          .then(response => response.json())
          .then(data => this.setState({ postId: data.id }));
        setCongrats(true);
      });
    });
  }
  const onEmailChange = (e) => {
    const em = e.target.value;
    setEmail(em);
  }

  const onMessageChange = (e) => {
    const me = e.target.value;
    setMessage(me);
  }
  return (
    <>
      <div id="contact-us" className="ContactUs">
        <div className="container-full green">
          <div className="container row">
            <section className="col m6 s12">
              <h2 className="title">Want to know more?</h2>
              <p>Contact us and our team of specialists will be happy to answer your questions</p>
            </section>
            <section className="col m6 s12">
              <div className="row">
                <form className="col s12">
                  <div className="input-row row">
                    <div className="input-field col s12">
                      <input
                        id="email"
                        type="email"
                        className="validate input-element"
                        value={email} onChange={onEmailChange.bind(this)}
                        placeholder="Your email" required />
                    </div>
                  </div>
                  <div className="input-row row">
                    <div className="input-field col s12">
                      <textarea
                        id="textarea"
                        className="materialize-textarea textarea-element"
                        value={message} onChange={onMessageChange.bind(this)}
                        placeholder="Your question" required></textarea>
                    </div>
                  </div>
                  <div className="input-row row">
                    <div className="input-field col s12">
                      {!congrats ? (
                        <button className="g-recaptcha waves-effect black btn" onClick={(e)=>{onSubmit(e)}}>CONTACT US</button>
                      ) : (
                        <h4 className="col s12">Thanks! We will reach out to you as soon as possible.</h4>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </div>
      <style>
        {`
          .ContactUs .container-full {
            padding-top: 120px;
            padding-left: 0px;
            padding-right: 0px;
            padding-bottom: 60px;
          }
          .ContactUs .title {
            margin-top: 0;
          }
          .ContactUs form {
            margin-top: 20px;
          }
          .ContactUs .input-row {
            margin-bottom: 0;
          }
          .ContactUs .input-field {
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 0;
            padding-bottom: 0;
          }
          .ContactUs .input-element,
          .ContactUs .textarea-element {
            margin-bottom: 10px !important;
            padding: 4px 8px !important;
            background: white !important;
            box-sizing: border-box !important;
            border-radius: 5px !important;
            font-size: 14px !important;
          }
          .ContactUs .textarea-element {
            min-height: 120px;
            padding-top: 14px !important;
          }
          .ContactUs .btn {
            height: 48px;
            padding: 0 24px;
          }
          .ContactUs .grecaptcha-badge { 
            visibility: hidden;
          }
        `}
      </style>
    </>
  );
};

export default ContactUs;

