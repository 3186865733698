import React, { useState, useEffect, useMemo } from 'react';

import { Checkbox } from './../../components/Form';

export default (props) => {

  const {single, info, form, setField, save, prev, isValid, isSaved} = props;
  useEffect(async () => {
    if(isSaved) setIsSaving(false)
  }, [isSaved])

  const [isSaving, setIsSaving] = useState(false);
  const onSave = (e) => {
    setIsSaving(true);
    save(e);
  }

  return (
    <form className="row" onSubmit={(event)=>{event.preventDefault()}}>
      <div className="col s12">
        <div className="row">
          <p>
            For us, it's very important that our investors understand that crypto-assets are a risky investment.
            In the very unlikely case of a security breach, you can lose your investment.
            Also, it's important to understand that crypto-assets are very volatile, 
            and its price can move up to 30% in a day.
          </p>
        </div>
        <div className="row">
          <p>
            If you have any questions regarding this, you can reach us at: <a href="mailto:abacus@carbono.com">abacus@carbono.com</a>
          </p>
        </div>
        <div className="row">
          <Checkbox
            id="risk_understood"
            form={form}
            onChange={setField}
            />
        </div>
      </div>
      <div className="footer col s12">
        {!single ? <button className="left waves-effect transparent btn" onClick={(e)=>prev(e)}>Previous</button> : null}
        <button className="right waves-effect black btn" disabled={!isValid||isSaving} onClick={(e)=>onSave(e)}>{single?'Save':'Next'}</button>
      </div>
    </form>
  );
}
