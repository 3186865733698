export const forms = [{
  title: {
    value: "Personal Info",
    icon: "&#xe7fd;"
  },
  url: {
    value: '/signup/1-personal-info',
    valid: true
  },
  full_name: {  
    value: null, 
    valid: null, 
    required: true, 
    label: "FULL NAME (AS APPEARS ON PASSPORT)"
  },
  date_of_birth: {  
    value: null, 
    valid: null, 
    required: true, 
    label: "DATE OF BIRTH"
  },
  address_residential: {  
    value: null, 
    valid: null, 
    required: true, 
    label: "RESIDENTIAL ADDRESS"
  },
  address_correspondence: {  
    value: null, 
    valid: null, 
    required: false, 
    label: "CORRESPONDENCE ADDRESS (IF DIFFERENT)"
  },
  phone_number: {  
    value: null, 
    valid: null, 
    required: true, 
    label: "PHONE"
  },
  tax_code: {  
    value: null, 
    valid: null, 
    required: true, 
    label: "TAX CODE (USUALLY YOUR ID NUMBER)"
  },
  country_residence: {  
    value: null, 
    valid: null, 
    required: true, 
    label: "COUNTRY OF RESIDENCE"
  },
  country_nationality: {  
    value: null, 
    valid: null, 
    required: true, 
    label: "NATIONALITY"
  },
  ic_passport_no: {  
    value: null, 
    valid: null, 
    required: true, 
    label: "PASSPORT NUMBER"
  },
  marital_status: {  
    value: null, 
    valid: null, 
    required: true, 
    label: "MARITAL STATUS"
  },
}, {
  title: {
    value: "Professional Info",
    icon: "&#xe8f9;"
  },
  url: {
    value: '/signup/2-professional-info',
    valid: true
  },
  question_howdidyoumakethemoney: {  
    value: null, 
    valid: null, 
    required: true, 
    label: "HOW DID YOU MAKE THE MONEY YOU ARE INVESTING?"
  },
  question_howdidyoumakeother: {  
    value: null, 
    valid: null, 
    required: false, 
    label: "IF \"OTHER\", PLEASE SPECIFY"
  },
  question_howlonginvest: {  
    value: null, 
    valid: null, 
    required: true, 
    label: "HOW LONG DO YOU PLAN TO INVEST?"
  },
  question_amounttoinvest_initial: {  
    value: null, 
    valid: null, 
    type: 'number',
    required: true, 
    min: 10000, 
    max: 10000000, 
    label: "INITIAL AMOUNT YOU PLAN TO INVEST (MIN. 10,000 USD)"
  },
  question_workingorretired: {  
    value: null, 
    valid: null, 
    required: true, 
    label: "EMPLOYMENT STATUS"
  },
  question_occupation: {  
    value: null, 
    valid: null, 
    required: true, 
    label: "WHAT IS YOUR OCCUPATION?"
  },
  question_employernameaddress: {  
    value: null, 
    valid: null, 
    required: true, 
    label: "NAME AND ADDRESS OF YOUR CURRENT EMPLOYER"
  },
  question_occupationlength: {  
    value: null, 
    valid: null, 
    required: true, 
    label: "HOW LONG HAVE YOU WORKED FOR THIS EMPLOYER?"
  },
  question_selfemployedactivity: {  
    value: null, 
    valid: null, 
    required: false, 
    label: "IF SELF-EMPLOYED, KINDLY DESCRIBE YOUR BUSINESS ACTIVITY"
  },
}, {
  title: {
    value: "Bank Info",
    icon: "&#xe84f;"
  },
  url: {
    value: '/signup/3-bank-info',
    valid: true
  },
  question_bank_name: {  
    value: null, 
    valid: null, 
    required: true, 
    label: "BANK NAME"
  },
  question_bank_address: {  
    value: null, 
    valid: null, 
    required: true, 
    label: "BANK ADDRESS AND POSTCODE"
  },
  question_bank_accountname: {  
    value: null, 
    valid: null, 
    required: true, 
    label: "ACCOUNT HOLDER'S NAME"
  },
  question_bank_swift: {  
    value: null, 
    valid: null, 
    required: true, 
    label: "BRANCH SWIFT CODE"
  },
  question_bank_sortcode: {  
    value: null, 
    valid: null, 
    required: false, 
    label: "BANK SORT CODE"
  },
  question_bank_ibanornumber: {  
    value: null, 
    valid: null, 
    required: true, 
    label: "ACCOUNT NUMBER OR IBAN"
  },
  question_bank_accountyears: {  
    value: null, 
    valid: null, 
    required: true, 
    label: "ACCOUNT HELD FOR",
    placeholder: 'YEARS'
  },
  question_bank_accountmonths: {  
    value: null, 
    valid: null, 
    required: true, 
    label: "",
    placeholder: 'MONTHS'
  },
}, {
  title: {
    value: "Suitability",
    icon: "&#xf0c5;"
  },
  url: {
    value: '/signup/4-additional-info',
    valid: true
  },
  question_politicallyexposed: {  
    value: null, 
    valid: null, 
    required: true, 
    label: ""
  },
  question_financialcrime: {  
    value: null, 
    valid: null, 
    required: true, 
    label: "HAVE YOU EVER BEEN CONVICTED OF ANY FINANCIAL-RELATED CRIME?"
  },
  question_financialcrimedetails: {  
    value: null, 
    valid: null, 
    required: false, 
    label: "IF YES, PLEASE STATE DETAILS"
  },
}, {
  title: {
    value: "Passport",
    icon: "&#xe03f;"
  },
  url: {
    value: '/signup/5-passport',
    valid: true
  },
  document_icpassport: {  
    value: null, 
    valid: null, 
    required: true, 
    label: "PASSPORT", 
    camera: true
  },
}, {
  title: {
    value: "Proof of residence",
    icon: "&#xe88a;"
  },
  url: {
    value: '/signup/6-proof-residence',
    valid: true
  },
  document_addressproof: {  
    value: null, 
    valid: null, 
    required: true, 
    label: "PROOF OF RESIDENTIAL ADDRESS", 
  },
}, {
  title: {
    value: "Risk assestment",
    icon: "&#xea17;"
  },
  url: {
    value: '/signup/7-risk',
    valid: true
  },
  risk_understood: {  
    value: null, 
    valid: null, 
    required: true, 
    label: "I UNDERSTAND THAT CRYPTO-ASSETS ARE RISKY, AND I CAN LOSE MY INVESTMENT."
  },
}, {
  title: {
    value: "Client agreement",
    icon: "&#xef76;"
  },
  url: {
    value: '/signup/8-agreement',
    valid: true
  },
  document_clientagreement: {  
    value: null, 
    valid: null, 
    required: true, 
  },
}]