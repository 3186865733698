import React, { useState, useEffect, useMemo } from 'react';

import { Radio, Textarea } from './../../components/Form';

export default (props) => {

  const {single, info, form, setField, save, prev, isValid, isSaved} = props;
  useEffect(async () => {
    if(isSaved) setIsSaving(false)
  }, [isSaved])

  const yesOrNo = useMemo(() => [
    "yes",
    "no",
  ].map(v=>({value:v, label:v})), [])

  const [isSaving, setIsSaving] = useState(false);
  const onSave = (e) => {
    setIsSaving(true);
    save(e);
  }

  return (
    <form className="row" onSubmit={(event)=>{event.preventDefault()}}>
      <div className="column col s12 m6">
        <div className="row">
          <label>ARE YOU, OR ANY PARTY CONNECTED TO YOU, POLITICALLY EXPOSED PERSONS (PEP)? <sup>*</sup></label>
          <small>
            <p>That is, you and any of your immediate family are:</p>
            <ul>
              <li>- Government officials either elected or unelected.</li>
              <li>- civil servants, or</li>
              <li>- prominent political party in any foreign country?</li>
            </ul>
          </small>
          <Radio
            id="question_politicallyexposed"
            form={form}
            options={yesOrNo}
            onChange={setField}
            />
        </div>
      </div>
      <div className="column col s12 m6">
        <div className="row">
          <Radio
            id="question_financialcrime"
            form={form}
            options={yesOrNo}
            onChange={setField}
            />
        </div>
        <div className="row">
          <Textarea
            id="question_financialcrimedetails"
            form={form}
            onChange={setField}
          />
        </div>
      </div>
      <div className="footer col s12">
        {!single ? <button className="left waves-effect transparent btn" onClick={(e)=>prev(e)}>Previous</button> : null}
        <button className="right waves-effect black btn" disabled={!isValid||isSaving} onClick={(e)=>onSave(e)}>{single?'Save':'Next'}</button>
      </div>
    </form>
  );
}
