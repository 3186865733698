import React, { useState, useEffect } from 'react';

import Team1Image from '../../../../assets/images/team-1.png';
import Team2Image from '../../../../assets/images/team-2.png';
import CarbonoImage from '../../../../assets/images/carbono.png';
import FarringdonImage from '../../../../assets/images/farringdon.png';
import TwitterImage from '../../../../assets/images/twitter.png';
import LinkedinImage from '../../../../assets/images/linkedin.png';

const Team = () => {
  return (
    <>
      <div className="Team">
        <div className="container-full">
          <div className="container row people">
            <div className="col s12">
              <h2>Get to know us</h2>
            </div>
            <div className="col m5 s12">
              <img className="member-image" src={Team1Image} width={440} height={264}/>
              <h5>
                Raúl Marcos
                <a href="" className="social-link">
                  <img src={TwitterImage} width={16} height={16} />
                </a>
                <a href="" className="social-link">
                  <img src={LinkedinImage} width={16} height={16} />
                </a>
              </h5>
              <h4>Computer Scientist, founder of Carbono -crypto advisory firm- and cryptocurrencies entrepreneur and investor since 2014.</h4>
              <hr/>
            </div>
            <div className="col m5 offset-m2 s12">
              <img className="member-image" src={Team2Image} width={440} height={264}/>
              <h5>
                Daniel Carnie
                <a href="https://www.linkedin.com/in/daniel-carnie-58138954/" className="social-link">
                  <img src={LinkedinImage} width={16} height={16} />
                </a>
              </h5>
              <h4>CEO at Farringdon Asset Management Malaysia. Over 15 years of experience in asset management, banking and financial planning.</h4>
              <hr/>
            </div>
          </div>
          <div className="container row companies">
            <div className="col m5 s12">
              <div className="company">
                <img src={CarbonoImage} width={172} height={25}/>
              </div>
              <b><u>Carbono</u> is an advisory firm specialized in cryptocurrencies that helps clients navigate the economic and financial innovations derived from the rise of digital assets: cryptocurrency investment, decentralized finance, tokenization, blockchain/crypto adoption, or due diligence of crypto-based startups.</b>
            </div>
            <div className="col m5 offset-m2 s12">
              <div className="company">
                <img src={FarringdonImage} width={138} height={33}/>
              </div>
              <b><u>Farringdon Asset Management</u> Pte Ltd is one of the largest independent asset managers in South East Asia, with over $3 billion under management through their Advisory and Asset Management Licenses in Singapore, USA, Dubai and Kuala Lumpur.</b>
            </div>
          </div>
        </div>
      </div>
      <style>
        {`
          .Team .container-full {
            padding-left: 0px;
            padding-right: 0px;
          }
          .Team .row {
            margin-top: 0;
            margin-bottom: 0;
          }
          .Team h6 {
            font-weight: bold;
            text-transform: uppercase;
          }
          .Team h5 {
            text-transform: uppercase;
            font-weight: 100;
          }
          .Team h4 {
            line-height: 150%;
          }
          .Team .member-image {
            border-radius: 4px;
          }
          .Team .social-link {
            display: inline-block;
            vertical-align: top;
            margin-left: 10px;
          }
          .Team hr {
            margin-top: 40px;
            border-top: 1px solid #09EC75;
          }
          .Team .company {
            min-height: 52px;
            margin-top: 40px;
            margin-bottom: 20px;
          }
          .Team img {
            max-width: 100%;
            height: auto;
          }
          .Team b {
            line-height: 175%;
          }
        `}
      </style>
    </>
  );
};

export default Team;

